import { Box, Button, TextField, Typography } from "@mui/material"
import { IMapForLiveDataAndForecastData } from "../libs/parametermapping"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface Props {
    parameter: IMapForLiveDataAndForecastData
    addCondition: any,
    updateCondition: any,
    removeCondition: any,
}

export const WeatherWindowCondition = (props: Props) => {

    const boxHeight = 30;

    return (

        <Box display="flex" justifyContent="space-between" sx={{height: boxHeight}}>

            {
                props.parameter.conditionFormat == "increasing" 
                ?
                <Box 
                    ml={10}
                    data-tag-permission={"Y"}
                    data-tag-current={true}
                    width={"40px"}
                    textAlign={"center"}
                    alignContent={"center"}
                >
                    <Typography variant="body2">Y</Typography>
                </Box>
                :
                props.parameter.conditionFormat == "decreasing"
                ?
                <Box  
                    ml={10}
                    data-tag-permission={"N"}
                    data-tag-current={true}
                    width={"40px"}
                    textAlign={"center"}
                    alignContent={"center"}
                >
                    <Typography variant="body2">N</Typography>
                </Box>
                :
                <></>
            }
            

            <Box display="flex" alignItems="center">
                <Typography variant="body2">≤</Typography>
            </Box>

            <Box display="flex" alignItems="center">
                <TextField
                    id={`${props.parameter.nameForecastData}-A`}
                    defaultValue={props.parameter.defaultLimitA} 
                    type="number"
                    // size="small"
                    
                    InputProps={{ 
                        inputProps: { 
                            step: "0.1",
                            min: 0,
                            max: document.getElementById(`${props.parameter.nameForecastData}-B`) ? document.getElementById(`${props.parameter.nameForecastData}-B`)["value"] : props.parameter.defaultLimitB,
                            style: {
                                height: boxHeight,
                                paddingTop: 0,
                                paddingBottom: 0,
                            }
                        }
                    }}
                    sx={{ input: { textAlign: 'center' }, width: "150px", maxHeight: "100%" }}
                    onChange={(e) => {
                        if (e.target.value.length > 0) {
                            props.updateCondition(props.parameter)
                        }
                    }}
                />
            </Box>

            <Box display="flex" alignItems="center">
                <Typography variant="body2">{"<"}</Typography>
            </Box>

            <Box 
                data-tag-permission={"R"}
                data-tag-current={true}
                width={"40px"}
                textAlign={"center"}
                alignContent={"center"}
            >
                <Typography variant="body2">R</Typography>
            </Box>

            <Box display="flex" alignItems="center">
                <Typography variant="body2">≤</Typography>
            </Box>

            <Box display="flex" alignItems="center">
                <TextField
                    id={`${props.parameter.nameForecastData}-B`}
                    defaultValue={props.parameter.defaultLimitB} 
                    type="number"
                    InputProps={{ 
                        inputProps: { 
                            step: "0.1",
                            min: document.getElementById(`${props.parameter.nameForecastData}-A`) ? document.getElementById(`${props.parameter.nameForecastData}-A`)["value"] : props.parameter.defaultLimitA,
                            style: {
                                height: boxHeight,
                                paddingTop: 0,
                                paddingBottom: 0,
                            }
                        } 
                    }}
                    sx={{ input: { textAlign: 'center' }, width: "150px"  }}
                    onChange={(e) => {
                        if (e.target.value.length > 0) {
                            props.updateCondition(props.parameter)
                        }
                    }}
                />
            </Box>

            <Box display="flex" alignItems="center">
                <Typography variant="body2">{"<"}</Typography>
            </Box>

            {
                props.parameter.conditionFormat == "increasing" 
                ?
                <Box 
                    mr={10}
                    data-tag-permission={"N"}
                    data-tag-current={true}
                    width={"40px"}
                    textAlign={"center"}
                    alignContent={"center"}
                >
                    <Typography variant="body2">N</Typography>
                </Box>
                :
                props.parameter.conditionFormat == "decreasing"
                ?
                <Box 
                    mr={10}
                    data-tag-permission={"Y"}
                    data-tag-current={true}
                    width={"40px"}
                    textAlign={"center"}
                    alignContent={"center"}
                >
                    <Typography variant="body2">Y</Typography>
                </Box>
                :
                <></>
            }

            <Box alignContent={"center"} sx={{margin: 0, padding: 0}}>
                <Button 
                    color="info"
                    onClick={() => props.removeCondition(props.parameter)}
                    sx={{margin: 0, padding: 0}}
                >
                    <DeleteOutlineIcon
                        sx={{margin: 0, padding: 0}}
                    />
                </Button>
            </Box>

        </Box>

        )}