import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import { createRoot } from "react-dom/client";
import React from "react";
import { Container, ThemeProvider } from "@mui/material";

import theme from "./theme";

// routes
import { MreConfig } from "./routes/mre/MreConfig";
import { TpxoConfig } from "./routes/tpxo/TpxoConfig";
import { ModulesOverview } from "./routes/ModulesOverview";
import { MapPage } from "./routes/map/MapPage";
import { Mm2DlpConfig } from "./routes/mm2dlp/Mm2DlpConfig";
import { McpMlConfig1 } from "./routes/mcp-ml/McpMlConfig1";
import { McpMlConfig0 } from "./routes/mcp-ml/McpMlConfig0";
import AuthProvider from "./contexts/authContext";
import { MopoConfig } from "./routes/mopo/MopoConfig";
import { WeatherWindowConfig } from "./routes/weather-window/WeatherWindowConfig";
import { PDFForecastsConfig } from "./routes/pdf-forecasts/PDFForecastsConfig";

const container = document.getElementById("app");
const root = createRoot(container);

const MainRoute: React.FunctionComponent = () => (
    <Routes>
        <Route path="/" element={<ModulesOverview />} />
        <Route path="/:lat/:lon" element={<ModulesOverview />} />
        <Route path="/mre" element={<MreConfig />} />
        <Route path="/mre/:lat/:lon" element={<MreConfig />} />
        <Route path="/tpxo" element={<TpxoConfig />} />
        <Route path="/tpxo/:lat/:lon" element={<TpxoConfig />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/map/:lat/:lon" element={<MapPage />} />
        <Route path="/mm2dlp" element={<Mm2DlpConfig />} />
        <Route path="/mm2dlp/:lat/:lon" element={<Mm2DlpConfig />} />
        <Route path="/mcp-ml" element={<McpMlConfig0 />} />
        <Route path="/mcp-ml/setup" element={<McpMlConfig1 />} />
        <Route path="/mcp-ml/setup/:transferedScheduleName" element={<McpMlConfig1 />} />
        <Route path="/mopo" element={<MopoConfig />} />
        <Route path="/weather-window" element={<WeatherWindowConfig />} />
        <Route path="/pdf-forecasts" element={<PDFForecastsConfig />} />
    </Routes>
);

    // root.render(
    //     <ThemeProvider theme={theme}>
    //         <BrowserRouter>
    //             <Container className="page-container">
    //                 <main className="page-main">
    //                     <MainRoute />
    //                 </main>
    //             </Container>
    //         </BrowserRouter>
    //     </ThemeProvider>
    // );

    root.render(
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Container className="page-container">
                    <main className="page-main">
                        <AuthProvider>
                            <MainRoute />
                        </AuthProvider>
                    </main>
                </Container>
            </BrowserRouter>
        </ThemeProvider>
    );
