export interface IMapForLiveDataAndForecastData {
    "label": string,
    "nameLiveData": string,
    "nameForecastData": string,
    "decimals": number,
    "unitOriginal": string,
    "unitScaling": number,
    "unitScaled": string
    "conditionFormat": "increasing" | "decreasing", // "increasing" is worse: Y <= a < R <= b < N. "decreasing" is worse: N <= a < R <= b < Y
    "defaultLimitA": number,
    "defaultLimitB": number,
    "computed"?: boolean,
}

export const mapForLiveDataAndOWSForecastData: IMapForLiveDataAndForecastData[] = [

    {
        "label": "Air Temperature",
        "nameLiveData": "AirTemperature_degC",
        "nameForecastData": "AirTempDryBulb_degC",
        "decimals": 1,
        "unitOriginal": "deg C",
        "unitScaling": 1,
        "unitScaled": "deg C",
        "conditionFormat": "decreasing",
        "defaultLimitA": -10,
        "defaultLimitB": 0,
    },
    // Ignored due to different sampling rate and/or range 
    // {
    //     "label": "Cloud Base Height",
    //     "nameLiveData": "First_CloudBaseHeight_ft",
    //     "nameForecastData": "First_CloudBaseHeight_ft",
    //     "decimals": 1,
    //     "unitOriginal": "ft",
    //     "unitScaling": 1,
    //     "unitScaled": "ft",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    {
        "label": "Gust Speed",
        "nameLiveData": "GustSpeed_kts",
        "nameForecastData": "GustSpeed_kts_10m",
        "decimals": 1,
        "unitOriginal": "kts",
        "unitScaling": 1,
        "unitScaled": "kts",
        "conditionFormat": "increasing",
        "defaultLimitA": 15,
        "defaultLimitB": 25,
    },
    // Ignored due to different sampling rate and/or range 
    // {
    //     "label": "Heading",
    //     "nameLiveData": "Heading_deg",
    //     "nameForecastData": "Heading_deg",
    //     "decimals": 1,
    //     "unitOriginal": "deg",
    //     "unitScaling": 1,
    //     "unitScaled": "deg",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    // {
    //     "label": "HMax",
    //     "nameLiveData": "HMax_m",
    //     "nameForecastData": "HMaxT_m",
    //     "decimals": 1,
    //     "unitOriginal": "m",
    //     "unitScaling": 1,
    //     "unitScaled": "m",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    // Excluded due to lack of liveData
    // {
    //     "label": "Hs Swell Component 1",
    //     "nameLiveData": "",
    //     "nameForecastData": "Hs_Swell1_m",
    //     "decimals": 1,
    //     "unitOriginal": "m",
    //     "unitScaling": 1,
    //     "unitScaled": "m",
    //     "conditionFormat": "increasing",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    // Excluded due to lack of liveData
    // {
    //     "label": "Hs Wind Sea",
    //     "nameLiveData": "",
    //     "nameForecastData": "Hs_WindSea_m",
    //     "decimals": 1,
    //     "unitOriginal": "m",
    //     "unitScaling": 1,
    //     "unitScaled": "m",
    //     "conditionFormat": "increasing",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    {
        "label": "Hs",
        "nameLiveData": "Hs_m",
        "nameForecastData": "Hs_m",
        "decimals": 1,
        "unitOriginal": "m",
        "unitScaling": 1,
        "unitScaled": "m",
        "conditionFormat": "increasing",
        "defaultLimitA": 2,
        "defaultLimitB": 3,
    },
    // Left out due to the requirement of more complex circular condition definition
    // {
    //     "label": "Mean Wave Direction Swell Component 1",
    //     "nameLiveData": "",
    //     "nameForecastData": "MeanWaveDirection_Swell1_deg",
    //     "decimals": 1,
    //     "unitOriginal": "deg",
    //     "unitScaling": 1,
    //     "unitScaled": "deg",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    // Left out due to the requirement of more complex circular condition definition
    // {
    //     "label": "Mean Wave Direction Wind Sea",
    //     "nameLiveData": "",
    //     "nameForecastData": "MeanWaveDirection_WindSea_deg",
    //     "decimals": 1,
    //     "unitOriginal": "deg",
    //     "unitScaling": 1,
    //     "unitScaled": "deg",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    // Left out due to the requirement of more complex circular condition definition
    // {
    //     "label": "Mean Wave Direction",
    //     "nameLiveData": "",
    //     "nameForecastData": "MeanWaveDirection_deg",
    //     "decimals": 1,
    //     "unitOriginal": "deg",
    //     "unitScaling": 1,
    //     "unitScaled": "deg",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_MCP_CURRENT_U_COMP_ms-1",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_MCP_CURRENT_V_COMP_ms-1",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_SOLAR_AM_CIVIL_TWLIGHT",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_SOLAR_PM_CIVIL_TWLIGHT",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_TIDE_DAILY_HIGH_1_TIME",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_TIDE_DAILY_HIGH_2_TIME",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_TIDE_DAILY_LOW_1_TIME",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_TIDE_DAILY_LOW_2_TIME",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_WEATHER_CONFIDENCE",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_WEATHER_LIGHTNING_DENSITY",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_WEATHER_LIGHTNING DENSITY_max_flashes_100km2_hour",
    //     "unitOriginal": ""
    // },
    // {
    //     "nameLiveData": "",
    //     "nameForecastData": "OWS_WEATHER_LIGHTNING_PROBABILITY",
    //     "unitOriginal": ""
    // },
    // Excluded due to uncertainty about the units
    // {
    //     "label": "Rainfall",
    //     "nameLiveData": "RainfallIntensity",
    //     "nameForecastData": "Rainfall_mm",
    //     "decimals": 1,
    //     "unitOriginal": "mm/hour",
    //     "unitScaling": 1,
    //     "unitScaled": "mm/hour",
    //     "conditionFormat": "increasing",
    //     "defaultLimitA": 10,
    //     "defaultLimitB": 20,
    // },
    {
        "label": "Relative Humidity",
        "nameLiveData": "RelativeHumidity_pc",
        "nameForecastData": "RelativeHumidity_pc",
        "decimals": 1,
        "unitOriginal": "pc",
        "unitScaling": 1,
        "unitScaled": "pc",
        "conditionFormat": "increasing",
        "defaultLimitA": 1,
        "defaultLimitB": 2,
    },
    // Excluded due to lack of liveData
    // {
    //     "label": "Tide",
    //     "nameLiveData": "",
    //     "nameForecastData": "Tide_m",
    //     "decimals": 1,
    //     "unitOriginal": "m",
    //     "unitScaling": 1,
    //     "unitScaled": "m",
    //     "conditionFormat": "increasing",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    // Left out due to the requirement of more complex circular condition definition
    // {
    //     "label": "Total Current Direction Surface",
    //     "nameLiveData": "Total_CurrentDirection_Surface_deg_Mean",
    //     "nameForecastData": "Total_CurrentDirection_Surface_deg_Mean",
    //     "decimals": 1,
    //     "unitOriginal": "deg",
    //     "unitScaling": 1,
    //     "unitScaled": "deg",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    {
        "label": "Total Current Speed Surface",
        "nameLiveData": "Total_CurrentSpeed_Surface_mps_Mean",
        "nameForecastData": "Total_CurrentSpeed_Surface_mps_Mean",
        "decimals": 2,
        "unitOriginal": "m/s",
        "unitScaling": 1.94384,
        "unitScaled": "kts",
        "conditionFormat": "increasing",
        "defaultLimitA": 1,
        "defaultLimitB": 2,
    },
    // Excluded due to lack of liveData
    // {
    //     "label": "Tp Swell Component 1",
    //     "nameLiveData": "",
    //     "nameForecastData": "Tp_Swell1_s",
    //     "decimals": 1,
    //     "unitOriginal": "s",
    //     "unitScaling": 1,
    //     "unitScaled": "s",
    //     "conditionFormat": "increasing",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    // Excluded due to lack of liveData
    // {
    //     "label": "Tp Wind Sea",
    //     "nameLiveData": "",
    //     "nameForecastData": "Tp_WindSea_s",
    //     "decimals": 1,
    //     "unitOriginal": "s",
    //     "unitScaling": 1,
    //     "unitScaled": "s",
    //     "conditionFormat": "increasing",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    {
        "label": "Tp",
        "nameLiveData": "Tp_s",
        "nameForecastData": "Tp_s",
        "decimals": 1,
        "unitOriginal": "s",
        "unitScaling": 1,
        "unitScaled": "s",
        "conditionFormat": "increasing",
        "defaultLimitA": 1,
        "defaultLimitB": 2,
    },
    {
        "label": "Visibility",
        "nameLiveData": "Visibility_m",
        "nameForecastData": "Visibility_m",
        "decimals": 1,
        "unitOriginal": "m",
        "unitScaling": 1e-3,
        "unitScaled": "km",
        "conditionFormat": "decreasing",
        "defaultLimitA": 0.6,
        "defaultLimitB": 1.852,
    },
    // Left out due to the requirement of more complex circular condition definition
    // {
    //     "label": "Wind Direction",
    //     "nameLiveData": "WindDirection_deg",
    //     "nameForecastData": "WindDirection_deg_10m",
    //     "decimals": 1,
    //     "unitOriginal": "deg",
    //     "unitScaling": 1,
    //     "unitScaled": "deg",
    //     "defaultLimitA": 1,
    //     "defaultLimitB": 2,
    // },
    // Excluded due to lack of liveData
    // {
    //     "label": "Wind Speed 100 m",
    //     "nameLiveData": "",
    //     "nameForecastData": "WindSpeed_kts_100m",
    //     "decimals": 1,
    //     "unitOriginal": "kts",
    //     "unitScaling": 1,
    //     "unitScaled": "kts",
    //     "conditionFormat": "increasing",
    //     "defaultLimitA": 15,
    //     "defaultLimitB": 25,
    // },
    {
        "label": "Wind Speed 10 m",
        "nameLiveData": "WindSpeed_kts",
        "nameForecastData": "WindSpeed_kts_10m",
        "decimals": 1,
        "unitOriginal": "kts",
        "unitScaling": 1,
        "unitScaled": "kts",
        "conditionFormat": "increasing",
        "defaultLimitA": 15,
        "defaultLimitB": 25,
    },
    // Excluded due to lack of liveData
    // {
    //     "label": "Wind Speed 50 m",
    //     "nameLiveData": "",
    //     "nameForecastData": "WindSpeed_kts_50m",
    //     "decimals": 1,
    //     "unitOriginal": "kts",
    //     "unitScaling": 1,
    //     "unitScaled": "kts",
    //     "conditionFormat": "increasing",
    //     "defaultLimitA": 15,
    //     "defaultLimitB": 25,
    // },
    {
        "label": "Roll Left",
        "nameLiveData": "Roll_Left_deg_Max",
        "nameForecastData": null,
        "decimals": 1,
        "unitOriginal": "deg",
        "unitScaling": 1,
        "unitScaled": "deg",
        "conditionFormat": "increasing",
        "defaultLimitA": 1,
        "defaultLimitB": 2,
    },
    {
        "label": "Roll Right",
        "nameLiveData": "Roll_Right_deg_Max",
        "nameForecastData": null,
        "decimals": 1,
        "unitOriginal": "deg",
        "unitScaling": 1,
        "unitScaled": "deg",
        "conditionFormat": "increasing",
        "defaultLimitA": 1,
        "defaultLimitB": 2,
    },
    {
        "label": "List",
        "nameLiveData": "List_deg",
        "nameForecastData": null,
        "decimals": 1,
        "unitOriginal": "deg",
        "unitScaling": 1,
        "unitScaled": "deg",
        "conditionFormat": "increasing",
        "defaultLimitA": 0.5,
        "defaultLimitB": 1.0,
    },
    // pseudo parameter (computed by us with getDaylightParameter() in mopo.ts)
    {
        "label": "Daylight",
        "nameLiveData": "Daylight_bool",
        "nameForecastData": "Daylight_bool",
        "decimals": 0,
        "unitOriginal": "bool",
        "unitScaling": 1,
        "unitScaled": "bool",
        "conditionFormat": "decreasing",
        "defaultLimitA": 0,
        "defaultLimitB": 0,
        "computed": true
    },
]


//// All available GroupValues from liveData
// AirTemperature_degC
// Buoy_Excursion_m
// Buoy_Latitude_deg
// Buoy_Longitude_deg
// Cmax_m
// CurrentProfile
// DewPoint_degC
// First_CloudBaseHeight_ft
// First_CloudBaseHeight_ft_Inst
// GustSpeed_kts
// GustSpeed_kts_2min
// GustSpeed_kts_10m
// Heave_Period_s_Mean
// Heave_Rate_mps_Max
// Heave_m_Max
// Helideck_Status
// Hmax_m
// Hs_m
// Inclination_deg_Max
// MSI
// MeanSpreading_deg
// MeanWaveDirection_deg
// PeakSpreading_deg
// PeakWaveDirection_deg
// PitchBowDown_deg_Max
// PitchBowUp_deg_Max
// PresentWeather
// QFE_mbar
// QNH_mbar
// RainfallIntensity_mmphour
// RelativeHumidity_pc
// Roll_Left_deg_Max
// Roll_Right_deg_Max
// Second_CloudBaseHeight_ft
// Second_CloudBaseHeight_ft_Inst
// T2_s
// Third_CloudBaseHeight_ft
// Third_CloudBaseHeight_ft_Inst
// Total_CurrentDirection_Surface_deg_Mean
// Total_CurrentSpeed_Surface_mps_Mean
// Tp_s
// Tz_s
// Visibility_m
// WaveSpectrum
// WindDirection_deg
// WindDirection_deg_2min
// WindDirection_deg_10min
// WindSpeed_kts
// WindSpeed_kts_2min
// WindSpeed_kts_10m
// WindSpeed_kts_10m_2min
// WindSpeed_kts_10m_10min
// WindSpeed_kts_10min