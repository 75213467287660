import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, Checkbox, DialogTitle, FormControlLabel, IconButton, Modal, Paper, Tooltip, Typography } from '@mui/material';
import { Title } from '@mui/icons-material';
// import Modal from '@mui/material/Modal';
import * as Plotly from 'plotly.js';
import Plot from 'react-plotly.js';
import { IForecastData, IForecastDataPoint, IForecastDataSeries, IHistoricalData, IHistoricalDataSeries } from '../types/IMopo';
import { IMapForLiveDataAndForecastData } from '../libs/parametermapping';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    selectedParameter: IMapForLiveDataAndForecastData | undefined, 
    setSelectedParameter: (filter: IMapForLiveDataAndForecastData | undefined) => void,
    historicalData: IHistoricalDataSeries,
    forecastData: IForecastDataSeries,
    timezone: string,
}

export default function ParameterPopover(props: Props) {

    console.log('[ParameterPopover] props.selectedParameter: ', props.selectedParameter)
    console.log('[ParameterPopover] props.historicalData: ', props.historicalData)
    console.log('[ParameterPopover] props.forecastData: ', props.forecastData)

    const handleClose = () => {
        props.setSelectedParameter(undefined);
    };

    const dataLayout = (color) => {

        return ({
            // type: 'scatter',
            // regarding compatibility between rangeslider and scattergl: https://github.com/plotly/plotly.js/issues/2627
            type: 'scattergl',
            mode: 'lines+markers',
            line: {
                dash: 'solid',
                width: 2,
                opacity: 0.7,
                color: color.replace('rgb', 'rgba').replace(')', ',0.5)')
            },
            marker: {
                size: 3.5,
                color: color
            }
        } as Partial<Plotly.ScatterData>)
    }

    var traceHistorical: Partial<Plotly.ScatterData> = { 
        name: 'Historical', 
        x: props.historicalData["Rows"].map((d) => moment(d["Timestamp"]).tz(props.timezone).format("YYYY-MM-DD HH:mm:ssZ")), 
        y: props.historicalData["Rows"].map((d) => d["Value"]), 
        xaxis: 'x',
        yaxis: 'y',
        ...dataLayout('rgb(75, 187, 187)'),
    };

    var traceForecast: Partial<Plotly.ScatterData> = { 
        name: 'Forecast', 
        x: props.forecastData["Rows"].map((d) => moment(d["Timestamp"]).tz(props.timezone).format("YYYY-MM-DD HH:mm:ssZ")), 
        y: props.forecastData["Rows"].map((d) => d["Value"]), 
        xaxis: 'x',
        yaxis: 'y',
        ...dataLayout('rgb(0, 0, 0)'),
    };

    const traces = [traceHistorical, traceForecast, ]

    const getXaxisRange = (forecastedHoursOi: number) => {

        const currentDatetime = moment().tz(props.timezone);

        // Set minimum to 24 hours prior to currentDatetime
        const xMin = currentDatetime.clone().subtract({"hours": 24}).format("YYYY-MM-DD HH:mm:ssZ");

        // Set maximum to forecastedHoursOi after to currentDatetime
        const xMax = currentDatetime.clone().add({"hours": forecastedHoursOi}).format("YYYY-MM-DD HH:mm:ssZ");

        return [xMin, xMax]
    }

    var updateMenus = [
        {
            type: "buttons",
            direction: "right",
            x: 0.7,
            y: 1.2,
            showactive: true,
            buttons: [
                {
                    label: '12 hours',
                    method: 'relayout',
                    args: [
                        { "xaxis.range": getXaxisRange(12)}
                    ],
                },
                {
                    label: '24 hours',
                    method: 'relayout',
                    args: [
                        { "xaxis.range": getXaxisRange(24)}
                    ],
                },
                {
                    label: '2 days',
                    method: 'relayout',
                    args: [
                        { "xaxis.range": getXaxisRange(24*2)}
                    ],
                },
                {
                    label: '7 days',
                    method: 'relayout',
                    args: [
                        { "xaxis.range": getXaxisRange(24*7)}
                    ],
                },
                {
                    label: 'All',
                    method: 'relayout',
                    args: [
                        { "xaxis.range": [
                                props.historicalData["Rows"].at(0)["Timestamp"], 
                                props.forecastData["Rows"].at(-1)["Timestamp"]
                            ],
                        },
                    ],
                },
            ],
        }
    ]

    const layout: Partial<Plotly.Layout> = {
        uirevision: true, // do not change zoom when new data is loaded
        useResizeHandler: true,
        height: 370,
        autosize: true,
        margin: {
            l: 40,
            r: 40,
            b: 40,
            t: 10,
            pad: 0
        },
        hovermode: 'x+closest',
        hoverdistance: 20,
        updatemenus: updateMenus,
        xaxis: {
            title: {
                text: `Time [${props.timezone}]`,

            },
            tickfont: {
                size: 10
            },
            type: 'date',
            tickformat: '%Y-%m-%d<br>%H:%M',
            showspikes: true,
            spikemode: 'across',
            spikesnap: 'hovered data',
            // showline: true,
            showgrid: true,
            spikedash: 'solid',
            spikecolor: '#292929',
            spikethickness: 2,
            // rangeselector: selectorOptions,
            rangeslider: {
                // regarding compatibility between rangeslider and scattergl: https://github.com/plotly/plotly.js/issues/2627
                visible: false
            },
            autorange: true, // do not change zoom when new data is loaded
        },
        yaxis: {
            title: {
                text: `${props.selectedParameter.label} [${props.selectedParameter.unitScaled}]`,
            },
            autorange: true, // do not change zoom when new data is loaded
        },
        legend: {
        },
    };

    return (
        <React.Fragment>
            <Modal
                open={props.selectedParameter != undefined}
                onClose={handleClose}
                // fullWidth={true}
                // fullScreen={true}
                // style={{ padding: '100px'}}
            >
                <Box 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80vw',
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        // boxShadow: 24,
                        p: 4,
                        borderRadius: '4px'
                    }}
                >
                    {/* CLOSE */}
                    <Box sx={{ 
                        position: 'relative', 
                        zIndex: 1 
                    }}>
                        <IconButton
                            sx={{ 
                                position: 'absolute',
                                top: -32,
                                right: -32,
                                // mb: 3
                            }}
                            color="info"
                            onClick={() => props.setSelectedParameter(undefined)}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Plot
                        data={traces}
                        layout={layout}
                        useResizeHandler={true}
                        style={{width: "100%", height: "100%"}}
                    />
                </Box>
            </Modal>
        </React.Fragment>
    );
}
