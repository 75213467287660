import "../../../style.css";

import React, { useEffect, useRef, useState } from "react";
import { MopoTable } from "../../components/MopoTable";
import _mopoSpecifications from '../../../../modules/mopo/output/adverseWeatherMatrix.json';
import { IActivity, IForecastData, IHistoricalData, IMopoSpecifications } from "../../types/IMopo";
import MenuAppBar from "../../components/MenuAppBar";
import useFetch from "../../hooks/useFetch";
import { ILiveData } from "../../types/ILiveData";
import ParameterPopover from "../../components/ParameterPopover";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, CircularProgress, IconButton, Modal } from "@mui/material";
import { formatLiveData, getForecastPermissions, getDaylightParameter, getLivePermissions, getParametersFromTest } from "../../libs/mopo";
import Forecast from "../../components/Forecast";
import { exportComponentAsPNG } from "react-component-export-image";
import { IConditionCategorisation } from "../../types/IWeatherWindow";
import { IMapForLiveDataAndForecastData, mapForLiveDataAndOWSForecastData } from "../../libs/parametermapping";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { row } from "mathjs";



export const MopoConfig = () => {

    // for exporting the Forecast
    const componentRef = useRef();

    // get fetch methods
    const { fetchMetNetGlobalLiveData, fetchMetNetGlobalHistoricalData, fetchMetNetGlobalForecastData } = useFetch();

    const [liveData, setLiveData] = useState<ILiveData | undefined>(undefined);
    const [historicalData, setHistoricalData] = useState<IHistoricalData | undefined>(undefined);
    const [forecastData, setForecastData] = useState<IForecastData | undefined>(undefined);

    const [stationId, setStationId] = useState<string>("Australia_Prelude");
    const timezone = stationId == "Australia_Prelude" ? "Australia/Perth" : "NA"
    const stationLatitude = stationId == "Australia_Prelude" ? -14.33782 : null;
    const stationLongitude = stationId == "Australia_Prelude" ? 121.87 : null;

    const [selectedActivity, setSelectedActivity] = useState<IActivity | undefined>(undefined);
    const [selectedParameter, setSelectedParameter] = useState<IMapForLiveDataAndForecastData | undefined>(undefined);

    // typify mopoSpecifications // TODO: how to do this properly? maybe neccesary because importing from json?
    const mopoSpecifications: IMopoSpecifications = _mopoSpecifications

    console.log('[LOG: MopoConfig] mopoSpecifications: ', mopoSpecifications)
    console.log('[LOG: MopoConfig] selectedParameter: ', selectedParameter)

    //// get conditionCategorisation - i.e. parameters for each conditionCategory of the mopoSpecifications
    let conditionCategorisation: IConditionCategorisation = {};

    for (const conditionCategory of mopoSpecifications.conditionCategories) {
        
        let parameters = [];

        for (const condition of conditionCategory.conditions) {

            // get parameters used in the liveData test
            const parametersUsedInLiveDataTest = getParametersFromTest(condition.test)

            // get parameters used in liveData tests
            for (const nameParameter of parametersUsedInLiveDataTest) {
                parameters.push(
                    mapForLiveDataAndOWSForecastData.filter(parameter => parameter.nameLiveData == nameParameter)[0]
                )
            }

            // get parameters used in the forecastData tests
            if (condition.forecastConditions) {
                for (const forecastCondition of condition.forecastConditions) {
                    if (forecastCondition.parameterGroupId != null) {
                        parameters.push(
                            mapForLiveDataAndOWSForecastData.filter(parameter => parameter.nameForecastData == forecastCondition.parameterGroupId)[0]
                        )
                    }
                }
            }
        }

        // remove duplicates
        parameters = Array.from(new Set(parameters));

        // add parameters to conditionCategorisation
        if (parameters.length > 0) {
            conditionCategorisation = {
                ...conditionCategorisation,
                [conditionCategory.label]: parameters
            }
        }

    }

    console.log('[LOG: MopoConfig] conditionCategorisation: ', conditionCategorisation);

    // get plain array of parameters of interest
    const parametersOi: IMapForLiveDataAndForecastData[] = Object.keys(conditionCategorisation).reduce((accumulator, currentLabel) => [...accumulator, ...conditionCategorisation[currentLabel].map(parameter => parameter)], []);

    console.log('[LOG: MopoConfig] parametersOi: ', parametersOi)

    useEffect(() => {

        // define function for fetching all data
        const fetchData = async () => {

            console.log('[LOG] Computing Daylight_bool parameter');

            const timeNow = moment(); // moment().unix() gives times in [s]

            const historicalEndTime = Math.round(timeNow.unix()); // [s]
            const nHistoricalDaysOi = 7;
            const historicalStartTime = historicalEndTime - (nHistoricalDaysOi * 24 * 60 * 60); // [s]

            // for the computed Daylight_bool parameter
            const timeResolution = 30 * 60 * 1e3; // 30 minutes in [ms]
            const forecastStartTime = Math.round(timeNow.unix() * 1e3 / timeResolution) * timeResolution - timeResolution; // nearest 30 minute timestamp [ms]
            const forecastTimestamps = Array.from({ length: 337 }, (_, idx) => forecastStartTime + (idx * timeResolution)); // [ms]
            const historicalTimestamps = Array.from({ length: (nHistoricalDaysOi*24*60*60*1e3 / timeResolution) }, (_, idx) => forecastStartTime - (idx * timeResolution)); // [ms]

            console.log('[HERE] forecastTimestamps: ', forecastTimestamps)
            console.log('[HERE] historicalTimestamps: ', historicalTimestamps)

            //// fetch

            console.log('[LOG] Fetching liveData, historicalData, and forecastData');

            fetchMetNetGlobalLiveData(stationId)
                .then(
                    (data) => setLiveData({

                        ...formatLiveData(data, parametersOi),

                        // add the computed Daylight_bool parameter
                        "Daylight_bool": {
                            Timestamp: timeNow.unix()*1e3,
                            Value: getDaylightParameter(timeNow.unix()*1e3, stationLatitude, stationLongitude),
                            Sensor: "calculated",
                            Parameter: "Daylight_bool",
                            ParameterGroup: "Daylight_bool",
                            ExpiryStatus: 0,
                            Primary: true,
                            StationId: stationId,
                        }
                    })
                );

            for (const parameter of parametersOi) {

                if (parameter.nameLiveData && !parameter.computed) {
                    fetchMetNetGlobalHistoricalData(stationId, parameter.nameLiveData, historicalStartTime, historicalEndTime).then(
                        (data) => setHistoricalData(previous => ({...previous, [parameter.nameLiveData]: data}))
                    );
                };

                if (parameter.nameForecastData && !parameter.computed) {
                    fetchMetNetGlobalForecastData(stationId, parameter.nameForecastData).then(
                        (data) => setForecastData(previous => ({...previous, [parameter.nameForecastData]: data}))
                    );
                };
            };

            // add the computed Daylight_bool parameter to historicalData
            setHistoricalData(previous => ({
                ...previous,
                "Daylight_bool": {
                    RequestedIssueTime: null,
                    T0Timestamp: null,
                    Rows: historicalTimestamps.map(time => {return {
                        CalculatedDiffT0: null,
                        CalculatedDiffT0_ms: null,
                        Hours: null,
                        Timestamp: moment(time).format("YYYY-MM-DD HH:mm:ssZ"),
                        Timestamp_ms: time,
                        Value: getDaylightParameter(time, stationLatitude, stationLongitude),
                    }})
                }
            }))

            // add the computed Daylight_bool parameter to forecastData
            setForecastData(previous => ({
                ...previous,
                "Daylight_bool": {
                    RequestedIssueTime: null,
                    T0Timestamp: null,
                    Rows: forecastTimestamps.map(time => {return {
                        CalculatedDiffT0: null,
                        CalculatedDiffT0_ms: null,
                        Hours: null,
                        Timestamp: moment(time).format("YYYY-MM-DD HH:mm:ssZ"),
                        Timestamp_ms: time,
                        Value: getDaylightParameter(time, stationLatitude, stationLongitude),
                    }})
                }
            }))
        
        };

        // fetch initial data
        fetchData();

        // set up interval to fetch data every 60 s
        const interval = setInterval(fetchData, 60000); 
    
        // clean up
        return () => clearInterval(interval);

    }, []);

    console.log('[LOG: MopoConfig] liveData: ', liveData);
    console.log('[LOG: MopoConfig] historicalData: ', historicalData);
    console.log('[LOG: MopoConfig] forecastData: ', forecastData);

    if (!liveData || !historicalData || !forecastData) {

        // if data is not yet loaded, indicate loading

        return (
            <Grid
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: '100vh',
                    width: '100vw'
                }}
            >
                <CircularProgress />
            </Grid>
        )

    } else {

        return (
            <div style={{ 
                height: "100vh", 
                width: "100vw", 
                position: "absolute",
                overflow: "hidden"
            }}>
                <MenuAppBar moduleName="Prelude MOPO" fullWidthPage={true} />

                <div style={{
                    width: '95vw', 
                    margin: 'auto', 
                    overflowY: 'scroll',
                    marginTop: '80px',
                    height: "calc(100% - 80px)"
                }}>
                    <MopoTable 
                        mopoSpecification={mopoSpecifications}
                        liveData={liveData}
                        setSelectedActivity={setSelectedActivity}
                        conditionCategorisation={conditionCategorisation}
                        timezone={timezone}
                    />
                </div>

                {/* Render Forecast as popover - if an activity has been selected*/}
                {selectedActivity &&
                    <React.Fragment>
                        <Modal
                            open={selectedActivity != undefined}
                            onClose={() => setSelectedActivity(undefined)}
                        >
                            <Box 
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '80vw',
                                    bgcolor: 'background.paper',
                                    border: '0px solid #000',
                                    p: 4,
                                    borderRadius: '4px',
                                }}
                            >
                                {/* CLOSE */}
                                <Box sx={{ 
                                    position: 'relative', 
                                    zIndex: 1 
                                }}>
                                    <IconButton
                                        sx={{ 
                                            position: 'absolute',
                                            top: -32,
                                            right: -32,
                                            // mb: 3
                                        }}
                                        color="info"
                                        onClick={() => setSelectedActivity(undefined)}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                </Box>
                                {/* Export as PNG */}
                                <Box sx={{ 
                                    position: 'relative', 
                                    zIndex: 1 
                                }}>
                                    <Button 
                                        sx={{ 
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            mb: 3
                                        }}
                                        variant="outlined"
                                        color="info"
                                        onClick={() => exportComponentAsPNG(componentRef, {fileName: `Forecast - ${selectedActivity.label}.png`})}
                                    >
                                        Export As PNG
                                    </Button>
                                </Box>
                                {/* Forecast */}
                                <Forecast 
                                    activityLabel={selectedActivity.label}
                                    timezone={timezone}
                                    liveData={liveData}
                                    livePermissions={
                                        getLivePermissions(
                                            selectedActivity,
                                            mopoSpecifications,
                                            liveData,
                                            mapForLiveDataAndOWSForecastData
                                        )
                                    }
                                    forecastData={forecastData}
                                    forecastPermissions={
                                        getForecastPermissions(
                                            selectedActivity,
                                            mopoSpecifications,
                                            forecastData,
                                            mapForLiveDataAndOWSForecastData
                                        )
                                    }
                                    setSelectedParameter={setSelectedParameter}
                                    mopoSpecifications={mopoSpecifications}
                                    ref={componentRef}
                                    conditionCategorisation={conditionCategorisation}
                                />
                            </Box>
                        </Modal>
                    </React.Fragment>
                }

                {/* Render parameter plot as popover - if a parameter has been selected*/}
                {selectedParameter &&
                    <ParameterPopover 
                        selectedParameter={selectedParameter}
                        setSelectedParameter={setSelectedParameter}
                        historicalData={historicalData[selectedParameter.nameLiveData]}
                        forecastData={forecastData[selectedParameter.nameForecastData]}
                        timezone={timezone}
                    />
                }
            </div>
        )
    }
};
