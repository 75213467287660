import { ILiveData, ILiveDataSet } from "../types/ILiveData";
import { IActivity, IForecastData, IForecastPermissions, IMopoSpecifications, IPermission } from "../types/IMopo";
import { IWeatherWindowConfig } from "../types/IWeatherWindow";
import { formatValue, getParametersFromTest } from "./mopo";
import { IMapForLiveDataAndForecastData, mapForLiveDataAndOWSForecastData as parameterMap } from "./parametermapping";



export const getForecastPermissions = (
    selectedActivity: IActivity,
    weatherWindowConfig: IWeatherWindowConfig, 
    forecastData: IForecastData,
    parameterMap: IMapForLiveDataAndForecastData[]
) => {

    // Returns the permissions for the selectedActivity for each 
    // point in the forecastData.

    //// get applied conditions

    let allForecastPermissions: IForecastPermissions = {};

    // go through each parameter of the forecastData
    for (const [nameParameter, data] of Object.entries(forecastData)) {

        let forecastPermissions: (null | IPermission)[] = data.Rows ? Array(data.Rows.length).fill(null) : Array(1).fill(null);

        // go through each condition
        for (const condition of weatherWindowConfig.conditions) {

            // check if forecastConditions exists (this is not certain due to incomplete mopo specs)
            if (condition.forecastConditions) {
                // go through each forecastCondition
                for (const forecastCondition of condition.forecastConditions) {
                    // if the forecastCondition has parameterGroupId == parameter, test is this condition is applied
                    if (forecastCondition.parameterGroupId == nameParameter) {

                        const testFunction = eval(forecastCondition.test)

                        // go through all data points
                        data.Rows.forEach((row, idx) => {

                            // get formattedValue
                            const formattedValue = formatValue(row.Value, parameterMap.filter(parameter => parameter.nameForecastData == nameParameter)[0])["Value"];

                            // get formattedData to input in testFunction
                            // ugly workaround for passing forecastData to testFunction
                            const formattedData = {[`${nameParameter}`]: {"Value": formattedValue}};

                            // test
                            if (testFunction(formattedData)) {

                                // get the appliedPermission from all permission of the selectedActivity
                                const appliedPermission = selectedActivity.permissions.filter((cA) => cA.conditionId == condition.conditionId)[0];

                                forecastPermissions[idx] = appliedPermission
                            } 
                        });
                    }    
                }
            }
        }

        allForecastPermissions[nameParameter] = forecastPermissions;

    }

    return (allForecastPermissions)
}



export const getLivePermissions = (
    selectedActivity: IActivity,
    weatherWindowConfig: IWeatherWindowConfig, 
    liveData: ILiveData,
    parameterMap: IMapForLiveDataAndForecastData[]
) => {

    // Returns the permissions for the selectedActivity for each 
    // point in the forecastData.

    //// get applied conditions

    let allPermissions: IForecastPermissions = {};

    // go through each parameter of the forecastData
    for (const [nameParameter, data] of Object.entries(liveData)) {

        console.log('[getLivePermissions] #### nameParameter: ', nameParameter)

        let permissions: (null | IPermission)[] = Array(1).fill(null);

        // go through each condition
        for (const condition of weatherWindowConfig.conditions) {

            const parameterFromTest = getParametersFromTest(condition.test)[0];

            if (parameterFromTest == nameParameter) {

                console.log('[getLivePermissions] condition: ', condition)
                console.log('[getLivePermissions] parameterFromTest: ', parameterFromTest)
                console.log('[getLivePermissions] parameterFromTest == nameParameter: ', parameterFromTest == nameParameter)

                const testFunction = eval(condition.test);

                // get formattedValue
                const formattedValue = formatValue(data.Value, parameterMap.filter(parameter => parameter.nameLiveData == nameParameter)[0])["Value"];

                // get formattedData to input in testFunction
                // ugly workaround for passing forecastData to testFunction
                const formattedData = {[`${nameParameter}`]: {"Value": formattedValue}};

                // test
                if (testFunction(formattedData)) {

                    // get the appliedPermission from all permission of the selectedActivity
                    const appliedPermission = selectedActivity.permissions.filter((cA) => cA.conditionId == condition.conditionId)[0];

                    console.log('[getLivePermissions] formattedData: ', formattedData)
                    console.log('[getLivePermissions] appliedPermission: ', appliedPermission)

                    permissions[0] = appliedPermission
                } 
            };

        }

        allPermissions[nameParameter] = permissions;

    }

    return (allPermissions)
}



export const getTests = (
    nameParameter: string,
    conditionFormat: string,
    limitA: number,
    limitB: number,

) => {

    let testY;
    let testR;
    let testN;

    if (conditionFormat == "increasing") {
        testY = `d => d[\"${nameParameter}\"][\"Value\"] <= ${limitA}`;
        testR = `d => d[\"${nameParameter}\"][\"Value\"] > ${limitA} && d[\"${nameParameter}\"][\"Value\"] <= ${limitB}`;
        testN = `d => d[\"${nameParameter}\"][\"Value\"] >= ${limitB}`;
    } else if (conditionFormat == "decreasing") {
        testY = `d => d[\"${nameParameter}\"][\"Value\"] >= ${limitB}`;
        testR = `d => d[\"${nameParameter}\"][\"Value\"] > ${limitA} && d[\"${nameParameter}\"][\"Value\"] <= ${limitB}`;
        testN = `d => d[\"${nameParameter}\"][\"Value\"] <= ${limitA}`;
    }

    return ([testY, testR, testN])
}