import * as React from 'react';
import { Box, Button, IconButton, Link, Modal, Table } from '@mui/material';
import { ListObjectsCommand, S3Client } from '@aws-sdk/client-s3';
import { ClearIcon } from '@mui/x-date-pickers';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface Props {
    isOpen: boolean,
    setIsOpen: any,
    // userId: string,
    listOfSavedConfigs: object[],
    loadConfigFromS3: any,
    deleteConfigFromS3: any,
}

export default function MyWeatherWindowActivitiesPopover(props: Props) {

    const handleClose = () => {
        props.setIsOpen(false);
    };

    console.log('[SavedWeatherWindowPopover] props.listOfSavedConfigs: ', props.listOfSavedConfigs)

    return (
        <React.Fragment>
            <Modal
                open={props.isOpen}
                onClose={handleClose}
            >
                <Box 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px',
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        // boxShadow: 24,
                        p: 4,
                        borderRadius: '4px'
                    }}
                >
                    {/* CLOSE */}
                    <Box sx={{ 
                        position: 'relative', 
                        zIndex: 1 
                    }}>
                        <IconButton
                            sx={{ 
                                position: 'absolute',
                                top: -32,
                                right: -32,
                                // mb: 3
                            }}
                            color="info"
                            onClick={() => props.setIsOpen(false)}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    {
                        props.listOfSavedConfigs
                        ? 
                        <Table>
                            <thead>
                                <th>Activity label</th>
                                <th>Last modified</th>
                            </thead>

                            <tbody>
                                {props.listOfSavedConfigs && props.listOfSavedConfigs.map(savedConfig => 
                                    
                                    <tr>
                                        <td
                                            onClick={() => {
                                                props.loadConfigFromS3(savedConfig.Key);
                                                props.setIsOpen(false);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <Link underline='none' color={'blue'}>
                                                {savedConfig.Key.split('/').at(-1).replace('.json', '')}
                                            </Link>
                                            
                                        </td>
                                        <td>
                                            {savedConfig.LastModified.toISOString().replace('T', ' ').substring(0, 19)}
                                        </td>
                                        <td width={"5px"}>
                                            <Button 
                                                color="info"
                                                sx={{margin: 0, padding: 0}}
                                                onClick={ () => 
                                                    props.deleteConfigFromS3(savedConfig.Key)
                                                }
                                            >
                                                <DeleteOutlineIcon 
                                                    sx={{margin: 0, padding: 0}}
                                                />
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        :
                        <Box>
                            You have no saved activities.
                        </Box>
                    }
                </Box>
            </Modal>
        </React.Fragment>
    );
}
function ensureAwsCredentials() {
    throw new Error('Function not implemented.');
}

