import "../../../style.css";

import React, { useContext, useEffect, useState } from "react";
import _mopoSpecifications from '../../../../modules/mopo/output/adverseWeatherMatrix.json';
import { IActivity, ICondition, IForecastData, IHistoricalData, IMopoSpecifications } from "../../types/IMopo";
import useFetch from "../../hooks/useFetch";
import { ILiveData, ILiveDataSetStation } from "../../types/ILiveData";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, CircularProgress, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Layout } from "../../layout";
import { IConditionCategorisation, IWeatherWindowConfig } from "../../types/IWeatherWindow";
import Forecast from "../../components/Forecast";
import { getForecastPermissions, getLivePermissions, getTests } from "../../libs/weatherwindow";
import { AuthContext } from "../../contexts/authContext";
import { formatLiveData, getDaylightParameter } from "../../libs/mopo";
import { IMapForLiveDataAndForecastData, mapForLiveDataAndOWSForecastData } from "../../libs/parametermapping";
import { WeatherWindowCondition } from "../../components/WeatherWindowCondition";
import ParameterPopover from "../../components/ParameterPopover";
import { DeleteObjectCommand, DeleteObjectsCommand, GetObjectCommand, ListObjectsCommand, ListObjectsOutput, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import MyWeatherWindowActivitiesPopover from "../../components/MyWeatherWindowActivitiesPopover";
import moment from "moment";



export const WeatherWindowConfig = () => {

    // typify mopoSpecifications // TODO: how to do this properly? maybe neccesary because importing from json?
    const mopoSpecifications: IMopoSpecifications = _mopoSpecifications

    console.log('[LOG] mopoSpecifications: ', mopoSpecifications)

    const { fetchMetNetGlobalLiveData, fetchMetNetGlobalHistoricalData, fetchMetNetGlobalForecastData } = useFetch();

    //// S3 stuff
    const { ensureAwsCredentials, sessionInfo } = useContext(AuthContext);

    //// useState
    const [liveData, setLiveData] = useState<ILiveData | undefined>(undefined);
    const [historicalData, setHistoricalData] = useState<IHistoricalData | undefined>(undefined);
    const [forecastData, setForecastData] = useState<IForecastData | undefined>(undefined);

    const [stationId, setStationId] = useState<string>("Australia_Prelude");
    const timezone = stationId == "Australia_Prelude" ? "Australia/Perth" : "NA"
    const stationLatitude = stationId == "Australia_Prelude" ? -14.33782 : null;
    const stationLongitude = stationId == "Australia_Prelude" ? 121.87 : null;

    const [selectedParameter, setSelectedParameter] = useState<IMapForLiveDataAndForecastData | undefined>(undefined);

    const [isLoadSavedWeatherWindowPopoverOpen, setIsLoadSavedWeatherWindowPopoverOpen] = useState<boolean>(false);
    const [listOfSavedConfigs, setListOfSavedConfigs] = useState<object[]>(undefined);

    const [userSpecifiedConditions, setUserSpecifiedConditions] = useState<ICondition[] | undefined>(undefined);

    const newActivity: IActivity = {
        id: undefined,
        label: undefined,
        permissions: [
            {
                conditionId: "Y",
                permitted: true,
                restriction: null
            },
            {
                conditionId: "R",
                permitted: true,
                restriction: "R"
            },
            {
                conditionId: "N",
                permitted: false,
                restriction: null
            }
        ]
    }

    const [activity, setActivity] = useState<IActivity>(newActivity)
    
    const [activityLabelError, setActivityLabelError] = useState<boolean>(false)

    const newConfig: IWeatherWindowConfig = {
        stationId: "Australia_Prelude",
        activityLabel: null,
        conditions: [] as ICondition[]
    }
    
    const [config, setConfig] = useState<IWeatherWindowConfig>(newConfig);

    console.log('[LOG: WeatherWindowConfig] config: ', config)

    //// S3
    const saveConfigToS3 = async (activityLabel: string) => {

        const awsConfig = await ensureAwsCredentials();

        const s3Client = new S3Client({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        const key = `modules/mopo/users/${sessionInfo.email}/${activityLabel}.json`;

        const response = await s3Client.send(
            new PutObjectCommand({
                Bucket: "shell-dw-module-output",
                Key: key,
                Body: JSON.stringify(config)
            })
        );

        if (response.$metadata.httpStatusCode == 200) {
            alert(`Your activity "${activityLabel}" was saved.`)

        } else {
            alert(`Your activity was not saved.\n\nhttpStatusCode: ${response.$metadata.httpStatusCode}`)
        }

        // Update the listOfSavedConfigs
        listSavedConfigs().then(listOfSavedConfigs => {
            setListOfSavedConfigs(listOfSavedConfigs);
        });

    }

    const loadConfigFromS3 = async (key: string) => {

        const awsConfig = await ensureAwsCredentials();

        const s3Client = new S3Client({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        const response = await s3Client.send(
            new GetObjectCommand({
                Bucket: "shell-dw-module-output",
                Key: key,
            })
        );

        setConfig(JSON.parse(await response.Body.transformToString()));

    }

    const deleteConfigFromS3 = async (key: string) => {

        const awsConfig = await ensureAwsCredentials();

        const s3Client = new S3Client({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        await s3Client.send(
            new DeleteObjectCommand({
                Bucket: "shell-dw-module-output",
                Key: key,
            })
        );

        // Update the listOfSavedConfigs
        listSavedConfigs().then(listOfSavedConfigs => {
            setListOfSavedConfigs(listOfSavedConfigs);
        });
    }

    const listSavedConfigs = async () => {

        const awsConfig = await ensureAwsCredentials();

        const s3Client = new S3Client({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        const response: ListObjectsOutput = await s3Client.send(
            new ListObjectsCommand({
                Bucket: "shell-dw-module-output",
                Prefix: `modules/mopo/users/${sessionInfo.email}/`,
            })
        );

        const savedConfig = response.Contents;

        return savedConfig
    }

    const conditionCategorisation: IConditionCategorisation = {
        // use all parameters with available forecastData
        'PARAMETERS': Array.from(
            new Set(config.conditions.map(condition => condition.forecastConditions[0].parameterGroupId))
        ).map(nameParameter => mapForLiveDataAndOWSForecastData.filter(parameter => parameter.nameForecastData == nameParameter)[0])
    }

    console.log('[LOG] conditionCategorisation: ', conditionCategorisation)

    const boxHeight = 30;

    const parametersOi: IMapForLiveDataAndForecastData[] = mapForLiveDataAndOWSForecastData.filter(parameter => parameter.nameForecastData)

    useEffect(() => {

        console.log('[LOG] parametersOi: ', parametersOi)

        listSavedConfigs().then(listOfSavedConfigs => {
            setListOfSavedConfigs(listOfSavedConfigs);
        });

        // define function for fetching all data
        const fetchData = async () => {

            console.log('[LOG] Fetching liveData, historicalData, and forecastData');

            const timeNow = moment(); // moment().unix() gives times in [s]

            const historicalEndTime = Math.round(timeNow.unix()); // [s]
            const nHistoricalDaysOi = 7;
            const historicalStartTime = historicalEndTime - (nHistoricalDaysOi * 24 * 60 * 60); // [s]

            // for the computed Daylight_bool parameter
            const timeResolution = 30 * 60 * 1e3; // 30 minutes in [ms]
            const forecastStartTime = Math.round(timeNow.unix() * 1e3 / timeResolution) * timeResolution - timeResolution; // nearest 30 minute timestamp [ms]
            const forecastTimestamps = Array.from({ length: 337 }, (_, idx) => forecastStartTime + (idx * timeResolution)); // [ms]
            const historicalTimestamps = Array.from({ length: (nHistoricalDaysOi*24*60*60*1e3 / timeResolution) }, (_, idx) => forecastStartTime - (idx * timeResolution)); // [ms]

            fetchMetNetGlobalLiveData(stationId)
                .then(
                    (data) => setLiveData({

                        ...formatLiveData(data, parametersOi),

                        // add the computed Daylight_bool parameter
                        "Daylight_bool": {
                            Timestamp: timeNow.unix()*1e3,
                            Value: getDaylightParameter(timeNow.unix()*1e3, stationLatitude, stationLongitude),
                            Sensor: "calculated",
                            Parameter: "Daylight_bool",
                            ParameterGroup: "Daylight_bool",
                            ExpiryStatus: 0,
                            Primary: true,
                            StationId: stationId,
                        }
                    })
                );

            for (const parameter of parametersOi) {

                if (parameter.nameLiveData) {
                    fetchMetNetGlobalHistoricalData(stationId, parameter.nameLiveData, historicalStartTime, historicalEndTime).then(
                        (data) => setHistoricalData(previous => ({...previous, [parameter.nameLiveData]: data}))
                    );
                }

                if (parameter.nameForecastData) {
                    fetchMetNetGlobalForecastData(stationId, parameter.nameForecastData).then(
                        (data) => setForecastData(previous => ({...previous, [parameter.nameForecastData]: data}))
                    );
                }
            };

            // add the computed Daylight_bool parameter to historicalData
            setHistoricalData(previous => ({
                ...previous,
                "Daylight_bool": {
                    RequestedIssueTime: null,
                    T0Timestamp: null,
                    Rows: historicalTimestamps.map(time => {return {
                        CalculatedDiffT0: null,
                        CalculatedDiffT0_ms: null,
                        Hours: null,
                        Timestamp: moment(time).format("YYYY-MM-DD HH:mm:ssZ"),
                        Timestamp_ms: time,
                        Value: getDaylightParameter(time, stationLatitude, stationLongitude),
                    }})
                }
            }))

            // add the computed Daylight_bool parameter to forecastData
            setForecastData(previous => ({
                ...previous,
                "Daylight_bool": {
                    RequestedIssueTime: null,
                    T0Timestamp: null,
                    Rows: forecastTimestamps.map(time => {return {
                        CalculatedDiffT0: null,
                        CalculatedDiffT0_ms: null,
                        Hours: null,
                        Timestamp: moment(time).format("YYYY-MM-DD HH:mm:ssZ"),
                        Timestamp_ms: time,
                        Value: getDaylightParameter(time, stationLatitude, stationLongitude),
                    }})
                }
            }))

        };

        // fetch initial data
        fetchData();

        // set up interval to fetch data every 60 s
        const interval = setInterval(fetchData, 60000); 
    
        // clean up
        return () => clearInterval(interval);

    }, []);

    console.log('[LOG: WeatherWindowConfig] liveData: ', liveData);
    console.log('[LOG: WeatherWindowConfig] historicalData: ', historicalData);
    console.log('[LOG: WeatherWindowConfig] forecastData: ', forecastData);

    //// get activityMenuItems
    let activityMenuItems = [];

    for (const activityCategory of mopoSpecifications.activityCategories) {
        
        // push activityCategory.label as unselectable header
        activityMenuItems.push(
            <Typography>
                <strong>{activityCategory.label}</strong>
            </Typography>
        )

        for (const activity of activityCategory.activities) {

            // push activity.label as selectable MenuItem
            activityMenuItems.push(
                <MenuItem 
                    value={activity.label}
                >
                    {activity.id}: {activity.label}
                </MenuItem>
            )

        }
    }

    const addCondition = (parameter: IMapForLiveDataAndForecastData) => {

        const [forecastTestY, forecastTestR, forecastTestN] = getTests(
            parameter.nameForecastData, 
            parameter.conditionFormat, 
            parameter.defaultLimitA, 
            parameter.defaultLimitB
        )

        const [liveTestY, liveTestR, liveTestN] = getTests(
            parameter.nameLiveData, 
            parameter.conditionFormat, 
            parameter.defaultLimitA, 
            parameter.defaultLimitB
        )

        const conditionsNew = [
            {
                "conditionId": "Y",
                "label": `${parameter.nameForecastData}-Y`,
                "test": liveTestY,
                "forecastConditions": [
                    {
                        "parameterGroupId": parameter.nameForecastData, 
                        "test": forecastTestY
                    }
                ]
            },
            {
                "conditionId": "R",
                "label": `${parameter.nameForecastData}-R`,
                "test": liveTestR,
                "forecastConditions": [
                    {
                        "parameterGroupId": parameter.nameForecastData, 
                        "test": forecastTestR
                    }
                ]
            },
            {
                "conditionId": "N",
                "label": `${parameter.nameForecastData}-N`,
                "test": liveTestN,
                "forecastConditions": [
                    {
                        "parameterGroupId": parameter.nameForecastData, 
                        "test": forecastTestN
                    }
                ]
            }
        ];

        setConfig({
            ...config, 
            "conditions": [
                ...config["conditions"],
                ...conditionsNew
            ]
        })
    }

    const updateCondition = (parameter: IMapForLiveDataAndForecastData) => {

        const limitA = document.getElementById(`${parameter.nameForecastData}-A`) ? document.getElementById(`${parameter.nameForecastData}-A`)["value"] : parameter.defaultLimitA;
        const limitB = document.getElementById(`${parameter.nameForecastData}-B`) ? document.getElementById(`${parameter.nameForecastData}-B`)["value"] : parameter.defaultLimitB;

        const [forecastTestY, forecastTestR, forecastTestN] = getTests(
            parameter.nameForecastData, 
            parameter.conditionFormat, 
            limitA, 
            limitB
        )

        const [liveTestY, liveTestR, liveTestN] = getTests(
            parameter.nameLiveData, 
            parameter.conditionFormat, 
            limitA, 
            limitB
        )

        let conditionsUpdated = [];

        // For each condition in the config, check if it relates to the parameter.nameForecastData.
        // If it relates to the parameter.nameForecastData, update it. Otherwise, keep it as is.
        for (const condition of config["conditions"]) {

            if (condition.label == `${parameter.nameForecastData}-Y`) {
                conditionsUpdated = [
                    ...conditionsUpdated, 
                    {
                        ...condition,
                        "test": liveTestY,
                        "forecastConditions": [
                            {
                                "parameterGroupId": parameter.nameForecastData, 
                                "test": forecastTestY
                            }
                        ]
                    }
                ];
            } else if (condition.label == `${parameter.nameForecastData}-R`) {
                conditionsUpdated = [
                    ...conditionsUpdated, 
                    {
                        ...condition,
                        "test": liveTestR,
                        "forecastConditions": [
                            {
                                "parameterGroupId": parameter.nameForecastData, 
                                "test": forecastTestR
                            }
                        ]
                    }
                ];
            } else if (condition.label == `${parameter.nameForecastData}-N`) {
                conditionsUpdated = [
                    ...conditionsUpdated, 
                    {
                        ...condition,
                        "test": liveTestN,
                        "forecastConditions": [
                            {
                                "parameterGroupId": parameter.nameForecastData, 
                                "test": forecastTestN
                            }
                        ]
                    }
                ];
            } else {
                conditionsUpdated = [...conditionsUpdated, condition];
            }
        }

        // update the config
        setConfig({
            ...config,
            "conditions": conditionsUpdated
        })
    }

    const removeCondition = (parameter: IMapForLiveDataAndForecastData) => {

        let conditionsUpdated = [];

        // For each condition in the config, check if it relates to the parameter.nameForecastData.
        // If it relates to the parameter.nameForecastData, update it. Otherwise, keep it as is.
        for (const condition of config["conditions"]) {
            // only keep the condition if it is not related to the parameter to be removed.
            if (!condition.label.includes(parameter.nameForecastData)) {
                conditionsUpdated = [...conditionsUpdated, condition];
            } 
        }

        // update the config
        setConfig({
            ...config,
            "conditions": conditionsUpdated
        })
    }

    function validateInputs() {

        console.log("[LOG] Validating inputs...");

        // Reset errors at the beginning of each validation
        let valid = true;

        setActivityLabelError(false);

        if (config.activityLabel == undefined || config.activityLabel == "" || config.activityLabel == null) {
            setActivityLabelError(true);
            valid = false;
        }

        // Raise alert if relevant
        if (!valid) {
            alert("Some values are invalid. Please check your inputs.");
            return false;
        } else {
            return true;
        }
    }

    if (!liveData || !forecastData) {

        const content = (
            <Grid
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: '100%',
                    width: '100%'
                }}
            >
                <CircularProgress />
            </Grid>
        )

        return <Layout content={content} moduleName="WeatherWindow" />;

    } else {

        const content =  (
            <div className="module-page">
                {/* <p className="module-guide-text">
                    This module generates a report on the yield of the{" "}
                    <strong>1 to 3 devices of interest</strong> at the <strong>Latitude</strong> and{" "}
                    <strong>Longitude</strong> based on data from the period with the length{" "}
                    <strong>Data range</strong> ending with <strong>Last month in range</strong>. The
                    report is sent to <strong>E-mail recipient</strong>.
                </p> */}
                <Grid 
                    container 
                    spacing={0.5} 
                    justifyContent="center" 
                    alignContent="center" 
                    textAlign="center"
                    columns={{ xs: 12, sm: 12 }}
                >
                    <Box
                        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                    >
                        {/* LEFT */}
                        <Box>
                            <Button 
                                variant="outlined" 
                                color="info"
                                fullWidth={true} 
                                sx={{height: boxHeight, width: "115px", mr: 0.5}}
                                onClick={() => {
                                    listSavedConfigs();
                                    setIsLoadSavedWeatherWindowPopoverOpen(true);
                                }}
                                
                            >
                                My activities
                            </Button>
                            <Button 
                                variant="outlined" 
                                color="info"
                                fullWidth={true} 
                                sx={{height: boxHeight, width: "115px"}}
                                onClick={() => {
                                    if (confirm('Do you want to create a new activity and discard the current?')) {
                                        setConfig(newConfig);
                                        setActivity(newActivity);
                                    }
                                }}
                            >
                                New
                            </Button>
                        </Box>

                        {/* RIGHT */}
                        <Box>
                            <Button 
                                variant="outlined"
                                color="info"
                                fullWidth={true}
                                sx={{height: boxHeight, width: "115px"}}
                                onClick={() => {

                                    // this is just to trigger the useEffect that fetched the listOfSavedConfigs
                                    setConfig(config);

                                    const overlapWithExistingConfigs = listOfSavedConfigs?.filter(savedConfig => savedConfig.Key.includes(`/${config.activityLabel}.json`));

                                    if (validateInputs()) {
                                        if (!overlapWithExistingConfigs || overlapWithExistingConfigs.length == 0 || (overlapWithExistingConfigs.length > 0 && confirm(`Do you wish to overwrite your activity "${config.activityLabel}"?`))) {
                                            saveConfigToS3(config.activityLabel)
                                        }
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>

                    <Grid xs={12}>
                        <Typography variant="h6" component="h2" mt={3} textAlign={"left"}>
                            Activity
                        </Typography>
                    </Grid>
                    <Grid xs={6}>
                        <FormControl 
                            variant="outlined" 
                            className="input-box"
                        >
                            <FormHelperText id="outlined-weight-helper-text">
                                {"Station"}
                            </FormHelperText>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={config["stationId"]}
                                sx={{height: boxHeight}}
                            >
                                {[config["stationId"]].map((name) => (
                                    <MenuItem value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={6} className="input-box">
                        <FormControl 
                            variant="outlined" 
                            className="input-box"
                            error={activityLabelError}
                        >
                            <FormHelperText id="outlined-weight-helper-text">
                                {"Activity label"}
                            </FormHelperText>
                            <Input 
                                sx={{height: boxHeight}}
                                defaultValue={config["activityLabel"] ? config["activityLabel"] : ""}
                                value={config["activityLabel"] ? config["activityLabel"] : ""}
                                onChange={ (e) => {
                                    setActivity({...activity, label: e.target.value, id: e.target.value})
                                    setConfig({...config, activityLabel: e.target.value})
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid xs={12} className="input-box">
                        <Typography variant="h6" component="h2" mt={3} textAlign={"left"}>
                            Configuration
                        </Typography>
                    </Grid>

                    {/* Parameters that were already added */}
                    {config.conditions.map((condition, idx) => {

                        // only render once per parameter. each parameter has multiple conditions.
                        if (condition.conditionId == 'Y') {

                            const parameterGroupId = condition.forecastConditions[0].parameterGroupId;
                            const parameter = mapForLiveDataAndOWSForecastData.filter(parameter => parameter.nameForecastData == parameterGroupId)[0]

                            return (
                                <>
                                <Grid xs={3}>
                                    <FormControl 
                                        variant="outlined" 
                                        fullWidth={true}
                                    >
                                        <Select
                                            value={`${parameter.label} [${parameter.unitScaled}]`}
                                            disabled={true}
                                            sx={{height: boxHeight}}
                                        >
                                            <MenuItem 
                                                value={`${parameter.label} [${parameter.unitScaled}]`}
                                            >
                                                {`${parameter.label} [${parameter.unitScaled}]`}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid xs={9} >
                                    <WeatherWindowCondition 
                                        parameter={parameter}
                                        addCondition={addCondition}
                                        updateCondition={updateCondition}
                                        removeCondition={removeCondition}
                                    />
                                </Grid>
                                </>
                            )
                        }

                        })}

                    {/* Add new parameter */}
                    <Grid xs={3} className="input-box">
                        <FormControl 
                            variant="outlined" 
                            sx={{
                                '&.MuiFormControl-root .MuiInputLabel-shrink': {
                                    display: 'none !important',
                                },
                                height: boxHeight
                            }}
                            fullWidth={true}
                            size='small'
                        >
                            <FormHelperText id="outlined-weight-helper-text">
                                {"Add parameter"}
                            </FormHelperText>
                            <Select
                                value={"hello"}
                                sx={{height: boxHeight}}
                            >
                                {parametersOi.map(parameter => {

                                    const appliedParameterGroups = config.conditions.map((condition) => condition.forecastConditions[0].parameterGroupId)

                                    // if a parameter is already applied, don't let the user select it again.
                                    if (!appliedParameterGroups.includes(parameter.nameForecastData)) {
                                        return (
                                            <MenuItem 
                                                value={`${parameter.label} [${parameter.unitScaled}]`}
                                                onClick={() => addCondition(parameter)}
                                            >
                                                {`${parameter.label} [${parameter.unitScaled}]`}
                                            </MenuItem>
                                        )
                                    }                                   
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid xs={9} />

                    <Grid xs={12}>
                        <Typography variant="h6" component="h2" mt={6} textAlign={"left"}>
                            Forecast
                        </Typography>
                    </Grid>

                    {
                        (config.conditions.length > 0) 
                        ?
                        <Grid xs={12} textAlign={"left"}>
                            <Forecast 
                                activityLabel={config.activityLabel}
                                timezone={timezone}
                                liveData={liveData}
                                livePermissions={
                                    getLivePermissions(
                                        activity,
                                        config,
                                        liveData,
                                        mapForLiveDataAndOWSForecastData
                                    )
                                }
                                forecastData={forecastData}
                                forecastPermissions={
                                    getForecastPermissions(
                                        activity,
                                        config,
                                        forecastData,
                                        mapForLiveDataAndOWSForecastData
                                    )
                                }
                                setSelectedParameter={setSelectedParameter}
                                mopoSpecifications={mopoSpecifications}
                                conditionCategorisation={conditionCategorisation}
                            />
                        </Grid>
                        :
                        'No parameters are configured...'
                    }

                </Grid>

                {selectedParameter &&
                    <ParameterPopover 
                        selectedParameter={selectedParameter}
                        setSelectedParameter={setSelectedParameter}
                        historicalData={historicalData[selectedParameter.nameLiveData]}
                        forecastData={forecastData[selectedParameter.nameForecastData]}
                        timezone={timezone}
                    />
                }

                {isLoadSavedWeatherWindowPopoverOpen &&
                    <MyWeatherWindowActivitiesPopover 
                        isOpen={isLoadSavedWeatherWindowPopoverOpen}
                        setIsOpen={setIsLoadSavedWeatherWindowPopoverOpen}
                        // userId={sessionInfo.email}
                        listOfSavedConfigs={listOfSavedConfigs}
                        loadConfigFromS3={loadConfigFromS3}
                        deleteConfigFromS3={deleteConfigFromS3}
                    />
                }
            </div>
        )

        return <Layout content={content} moduleName="WeatherWindow" />;
    }

};
