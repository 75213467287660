import { Button, ButtonGroup, Checkbox, TableCell, TableRow } from "@mui/material";
import { IOptions } from "../../routes/pdf-forecasts/PDFForecastsConfig";
import { IPDFForecastsRowData } from "./ForecastTable";



export const blankPDFForecastRowData: IPDFForecastsRowData = {
    StationName: "",
    Latitude: null,
    Longitude: null,
    Active: true,
    TimezoneOffset: 0,
    Type: 'ocean',
    UTCIssueTimes: [],
    DaysForecast: 1,
    Units: 'Metric',
    RecipientsEmails: [],
};


interface Props {
    data: IPDFForecastsRowData, 
    idx: number, 
    deleteRow: any, 
    editRow: any, 
    options: IOptions
}

export const Row = (props: Props) => {
    //: inspired from: https://www.youtube.com/watch?v=MINQoCSBmds  &&  https://github.com/CodeCompleteYT/react-table/blob/main/src/components/Table.jsx

    return (
        <>
            <TableRow
                key={props.idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {props.data.StationName}
                </TableCell>
                <TableCell align="center">{props.data.Latitude}</TableCell>
                <TableCell align="center">{props.data.Longitude}</TableCell>
                <TableCell align="center">
                    <Checkbox disabled checked={props.data.Active} sx={{ '& .MuiSvgIcon-root': { fontSize: props.options.checkboxSize } }} />
                </TableCell>
                <TableCell align="center">{props.data.TimezoneOffset}</TableCell>
                <TableCell align="center">{props.data.Type}</TableCell>
                <TableCell align="center">{props.data.UTCIssueTimes.join(', ')}</TableCell>
                <TableCell align="center">{props.data.DaysForecast}</TableCell>
                <TableCell align="center">{props.data.RecipientsEmails.join(', ')}</TableCell>
                <TableCell align="center">
                    <ButtonGroup size="small">
                        <Button variant="contained" onClick={() => props.editRow(props.idx, props.data)}>
                            <i className="fa fa-fw fa-pencil-square-o"></i>
                            <span>Edit</span>
                        </Button>
                        <Button variant="contained" onClick={() => props.deleteRow(props.idx, props.data)} color="error" /*startIcon={<DeleteIcon />}*/>
                            <i className="fa fa-fw fa-trash-o"></i>
                            <span>Remove</span>
                        </Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
        </>
    )
}