import { Alert, Autocomplete, Box, Button, ButtonGroup, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TableCell, TableRow, TextField } from "@mui/material";
import { IOptions } from "../../routes/pdf-forecasts/PDFForecastsConfig";
import { IPDFForecastsRowData } from "./ForecastTable";
import { blankPDFForecastRowData } from "./Row";
import { useState } from "react";



// export const blankPDFForecastRowData: PDFForecastsRowData = {
//     StationName: "",
//     Latitude: null,
//     Longitude: null,
//     Active: true,
//     TimezoneOffset: 0,
//     Type: 'ocean',
//     UTCIssueTimes: [],
//     DaysForecast: 1,
//     RecipientsEmails: [],
// };


interface Props {
    closeModal: any, 
    onSubmit: any, 
    defaultValue: IPDFForecastsRowData, 
    options: IOptions
}

export const Editor = (props: Props) => {
    
    const [formState, setFormState] = useState(
        props.defaultValue || blankPDFForecastRowData
    );
    console.log('formState: ', formState)
    // console.info('PDFForecasts.Editor', { closeModal, onSubmit, defaultValue, formState });
    const [errorFields, setErrorFields] = useState([]);
    
    const validateForm = () => {
        ///console.warn('DEV TODO: validate form data!', formState);
        let errorKeys = [];
    
        for (const i in Object.keys(formState)) {
            const key = Object.keys(formState)[i];
            const val = formState[key];
            if (!(key == "RecipientsEmails") && (val === undefined || val === null || val === '' || (key == "UTCIssueTimes" && Array.isArray(val) && val.length == 0))) {
                errorKeys.push(key);
            } else {
                if ((key == "Latitude" || key == "Longitude") && isNaN(parseFloat(formState[key].toString()))) {
                    errorKeys.push(key);
                }
                if ((key == "TimezoneOffset" || key == "DaysForecast") && isNaN(parseInt(formState[key].toString()))) {
                    errorKeys.push(key);
                }
            }
        }
        setErrorFields(errorKeys);
        return !(errorKeys.length > 0);
    };
    
    const handleChange = (e) => {
        setErrorFields([]);
        if (e.target.name == "Active") {
            setFormState({ ...formState, [e.target.name]: e.target.checked });
        } else {
            setFormState({ ...formState, [e.target.name]: e.target.value });
        }
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        props.onSubmit(formState);
        props.closeModal();
    };

    return (
        <>
            <Dialog open={true}>
                <DialogTitle>Edit PDF Forecast Station</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>
                        test...
                    </DialogContentText>*/}
                    <Stack spacing={2}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="StationName"
                            name="StationName"
                            label="Station Name"
                            required
                            type="text"
                            error={errorFields.indexOf("StationName") >= 0}
                            fullWidth
                            variant="standard"
                            defaultValue={formState.StationName}
                            onChange={handleChange}
                        />
                        <Grid container spacing={0} sx={{ width: '100%', m: 0 }}>
                            <Grid xs={6}>
                                <TextField
                                    margin="dense"
                                    id="Latitude"
                                    name="Latitude"
                                    label="Latitude"
                                    required
                                    type="text"
                                    error={errorFields.indexOf("Latitude") >= 0}
                                    fullWidth
                                    variant="standard"
                                    defaultValue={formState.Latitude}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <TextField
                                    margin="dense"
                                    id="Longitude"
                                    name="Longitude"
                                    label="Longitude"
                                    required
                                    type="text"
                                    error={errorFields.indexOf("Longitude") >= 0}
                                    fullWidth
                                    variant="standard"
                                    defaultValue={formState.Longitude}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <FormGroup>
                            <FormControlLabel
                                label="Active"
                                control={<Checkbox
                                    defaultChecked={formState.Active}
                                    name="Active"
                                    onChange={handleChange}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: props.options.checkboxSize } }}
                                />}
                            />
                        </FormGroup>
                        <TextField
                            margin="dense"
                            id="TimezoneOffset"
                            name="TimezoneOffset"
                            label="Timezone Offset"
                            required
                            type="number"
                            error={errorFields.indexOf("TimezoneOffset") >= 0}
                            fullWidth
                            variant="standard"
                            defaultValue={formState.TimezoneOffset}
                            onChange={handleChange}
                        />
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="type-select-label">Type</InputLabel>
                            <Select
                                labelId="type-select-label"
                                // id="demo-simple-select-standard"
                                name="Type"
                                required
                                error={errorFields.indexOf("Type") >= 0}
                                defaultValue={formState.Type}
                                onChange={handleChange}
                                label="Type">
                                <MenuItem value="ocean">ocean</MenuItem>
                                <MenuItem value="land">land</MenuItem>
                            </Select>
                        </FormControl>

                        <Autocomplete
                            multiple
                            id="UTCIssueTimes"
                            name="UTCIssueTimes"
                            error={errorFields.indexOf("UTCIssueTimes") >= 0}
                            options={formState.UTCIssueTimes}
                            defaultValue={formState.UTCIssueTimes}
                            onChange={(event, newValue: string[] | number[]) => { setFormState({ ...formState, ["UTCIssueTimes"]: newValue.map(val => parseInt(val)).sort((n1, n2) => n1 - n2) }) }}
                            freeSolo
                            renderTags={(value: readonly number[], getTagProps) =>
                                value.map((option: number, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="UTC Issue Times"
                                    placeholder="Add Issue Time"
                                    type="number"
                                    error={errorFields.indexOf("UTCIssueTimes") >= 0}
                                    helperText="To add an Issue Time, enter the value and press Enter."
                                />
                            )}
                        />

                        <TextField
                            margin="dense"
                            id="DaysForecast"
                            name="DaysForecast"
                            label="Days Forecast"
                            required
                            type="number"
                            error={errorFields.indexOf("DaysForecast") >= 0}
                            fullWidth
                            variant="standard"
                            defaultValue={formState.DaysForecast}
                            onChange={handleChange}
                        />

                        {/* <FormControl>
                            <FormHelperText sx={{margin: 0}} id="demo-radio-buttons-group-label">Units</FormHelperText>
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={formState.Units ? formState.Units : "Metric"}
                                name="Units"
                                sx={{display: 'flex', margin: 'auto'}}
                                onChange={handleChange}
                            >
                                <Box>
                                    <FormControlLabel
                                        key={0}
                                        value={"Metric"}
                                        control={<Radio />}
                                        label={"Metric"}
                                    />
                                    <FormControlLabel
                                        key={0}
                                        value={"US"}
                                        control={<Radio />}
                                        label={"US"}
                                    />
                                </Box>
                            </RadioGroup>
                        </FormControl> */}

                        <FormControl fullWidth variant="standard">
                            <InputLabel id="units-select-label">Units</InputLabel>
                            <Select
                                labelId="units-select-label"
                                name="Units"
                                required
                                error={errorFields.indexOf("Units") >= 0}
                                defaultValue={formState.Units ? formState.Units : "Metric"}
                                onChange={handleChange}
                                label="Units"
                            >
                                <MenuItem value="Metric">Metric</MenuItem>
                                <MenuItem value="US">US</MenuItem>
                            </Select>
                        </FormControl>

                        <Autocomplete
                            multiple
                            id="RecipientsEmails"
                            name="RecipientsEmails"
                            error={errorFields.indexOf("RecipientsEmails") >= 0}
                            options={formState.RecipientsEmails}
                            defaultValue={formState.RecipientsEmails}
                            onChange={(event, newValue: string[]) => { setFormState({ ...formState, ["RecipientsEmails"]: newValue }) }}
                            freeSolo
                            renderTags={(value: readonly string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Recipients Emails"
                                    placeholder="Add Recipient"
                                    type="email"
                                    error={errorFields.indexOf("RecipientsEmails") >= 0}
                                    helperText="To add a Recipient, enter the email and press Enter."
                                />
                            )}
                        />
                    </Stack>
                    {errorFields && errorFields.length > 0 &&
                        <Alert severity="error" sx={{ mt: 2 }}>{`Please complete or address the fields highlighted above.`}</Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.closeModal}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}