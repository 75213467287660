import * as React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { IActivity, IForecastData, IMopoSpecifications } from '../types/IMopo';
import { formatValue, getLiveDataRange } from '../libs/mopo';
import { exportComponentAsPNG } from 'react-component-export-image';
import { IConditionCategorisation } from '../types/IWeatherWindow';
import { IMapForLiveDataAndForecastData, mapForLiveDataAndOWSForecastData } from '../libs/parametermapping';
import { useState } from 'react';
import moment from 'moment-timezone';

interface Props {
    activityLabel: string, 
    timezone: string,
    liveData: any,
    livePermissions?: any,
    forecastData: IForecastData,
    forecastPermissions: any,
    setSelectedParameter: (filter: IMapForLiveDataAndForecastData | undefined) => void,
    mopoSpecifications: IMopoSpecifications,
    conditionCategorisation: IConditionCategorisation,
}

const Forecast = React.forwardRef((props: Props, ref) => {

    const [resolution, setResolution] = useState<number>(3); // [hours]

    console.log('[Forecast] props.liveData: ', props.liveData)
    console.log('[Forecast] props.liveDataPermissions: ', props.livePermissions)
    console.log('[Forecast] props.forecastData: ', props.forecastData)
    console.log('[Forecast] props.forecastPermissions: ', props.forecastPermissions)

    // get the temporal range of the liveData
    const liveDataRange = getLiveDataRange(props.liveData, props.conditionCategorisation);
    console.log('[Forecast] liveDataRange: ', liveDataRange)

    //// filter forecastData and forecastPermissions to only include data from timestamps >= liveDataRange.Min
    let forecastDataOi: IForecastData = {};
    let forecastPermissionsOi = {};

    for (const parameter of Object.keys(props.forecastData)) {
        
        forecastDataOi = {
            ...forecastDataOi,
            [parameter]: {
                "Rows": props.forecastData[parameter]["Rows"].filter(row => row["Timestamp_ms"] >= liveDataRange.Min),
                RequestedIssueTime: null, 
                T0Timestamp: null
            }
        }

        forecastPermissionsOi = {
            ...forecastPermissionsOi,
            [parameter]: props.forecastPermissions[parameter].filter((d, idx) => props.forecastData[parameter]["Rows"][idx]["Timestamp_ms"] >= liveDataRange.Min)
        }
    }

    // forecastDataOi = forecastDataOi as IForecastData;

    console.log('[Forecast] forecastDataOi: ', forecastDataOi)
    console.log('[Forecast] forecastPermissionsOi: ', forecastPermissionsOi)

    //// RE-SAMPLE forecastData

    let forecastDataBinned = {};
    let forecastPermissionsBinned = {};

    for (const [parameterName, parameterData] of Object.entries(forecastDataOi)) {

        console.log('[Forecast] parameterData of forecastDataOi: ', parameterData)

        const timeDifference = moment.utc().tz(props.timezone).utcOffset(); // [minutes]

        // get time range edges
        let timeRangeEdges = parameterData.Rows.filter(row => ((row.Timestamp_ms + (timeDifference*60*1000)) % (resolution*60*60*1000)) == 0).map(row => row.Timestamp_ms)

        console.log('[Forecast] timeRangeEdges', parameterName, timeRangeEdges)

        // arrange rows according to the timeRangeEdges
        let idx = 0;

        forecastDataBinned[parameterName] = {};
        forecastDataBinned[parameterName][idx] = [];

        forecastPermissionsBinned[parameterName] = {};
        forecastPermissionsBinned[parameterName][idx] = [];

        for (const [idxRow, row] of parameterData.Rows.entries()) {

            if (row.Timestamp_ms < timeRangeEdges[idx]) {
                forecastDataBinned[parameterName][idx].push(row);
                forecastPermissionsBinned[parameterName][idx].push(forecastPermissionsOi[parameterName][idxRow]);
            } else if (timeRangeEdges[idx+1]) {
                idx += 1;
                forecastDataBinned[parameterName][idx] = [];
                forecastDataBinned[parameterName][idx].push(row);

                forecastPermissionsBinned[parameterName][idx] = [];
                forecastPermissionsBinned[parameterName][idx].push(forecastPermissionsOi[parameterName][idxRow]);
            }

        }

    }

    console.log('[Forecast] forecastDataBinned', forecastDataBinned)
    console.log('[Forecast] forecastPermissionsBinned', forecastPermissionsBinned)

    let forecastDataResampled = {};
    let forecastPermissionsResampled = {};

    for (const [parameterName, parameterData] of Object.entries(forecastDataBinned)) {

        const parameterMap = mapForLiveDataAndOWSForecastData.filter(parameterMap => parameterMap.nameForecastData == parameterName)[0];

        forecastDataResampled[parameterName] = {"Rows": []};
        forecastPermissionsResampled[parameterName] = [];

        // console.log('[Forecast] parameterData: ', parameterData)

        for (const [idx, bin] of Object.entries(parameterData)) {

            if (bin.length > 0) {
                const values = bin.map(item => parseFloat(item.Value));

                const worstCaseValue = parameterMap.conditionFormat == "increasing" ? Math.max(...values) : Math.min(...values);
                const worstCaseIdx = values.indexOf(worstCaseValue);
                const worstCasePermission = forecastPermissionsBinned[parameterName][idx][worstCaseIdx];

                forecastDataResampled[parameterName].Rows.push(
                    {
                        "Timestamp": bin[0]["Timestamp"],
                        "Timestamp_ms": bin[0]["Timestamp_ms"],
                        "Value": worstCaseValue.toString()
                    }
                )

                forecastPermissionsResampled[parameterName].push(
                    worstCasePermission
                );
            }

            if (Number(idx) == Object.keys(parameterData).length - 1) {
                forecastDataResampled[parameterName].Rows.push(
                    {
                        "Timestamp": null,
                        "Timestamp_ms": bin[0]["Timestamp_ms"] + resolution*60*60*1000,
                        "Value": null
                    }
                )
        
                forecastPermissionsResampled[parameterName].push(
                    null
                );
            }
        }

    }

    console.log('[Forecast] forecastDataResampled', forecastDataResampled)
    console.log('[Forecast] forecastPermissionsResampled', forecastPermissionsResampled)

    //// from the forecastDataResampled, get dates and their colspans

    const dataOi = forecastDataResampled[Object.keys(forecastDataResampled)[0]]["Rows"];

    // initialise count and array
    let dateCount = 0;
    let timestampsAndColspans = [];

    dataOi.forEach((_, idx) => {

        // add count
        dateCount += 1;

        if (!dataOi[idx+1] || moment(dataOi[idx]["Timestamp_ms"]).tz(props.timezone).format("YYYY-MM-DD") != moment(dataOi[idx+1]["Timestamp_ms"]).tz(props.timezone).format("YYYY-MM-DD")) {

            timestampsAndColspans.push({
                "Timestamp_ms": dataOi[idx]["Timestamp_ms"],
                "colspan": dataOi[idx+1] ? dateCount : dateCount - 1
            })

            // reset count
            dateCount = 0;
        }

    });

    console.log('[Forecast] timestampsAndColspans: ', timestampsAndColspans)


    return (
        <>
            {/* TIME RANGE BUTTONS */}
            <Box sx={{ position: 'relative'}}>
                <Button 
                    variant="outlined"
                    color="info"
                    sx={{
                        textTransform: "None",
                        backgroundColor: resolution == 1 ? 'lightgrey' : 'None',
                        mr: 0.25
                    }}
                    onClick={() => setResolution(1)}
                    title='Show the worst case value within 1-hour intervals.'
                >
                    1 hour
                </Button>
                <Button 
                    variant="outlined"
                    color="info"
                    sx={{
                        textTransform: "None",
                        backgroundColor: resolution == 3 ? 'lightgrey' : 'None',
                        mr: 0.25
                    }}
                    onClick={() => setResolution(3)}
                    title='Show the worst case value within 3-hour intervals.'
                >
                    3 hours
                </Button>
                <Button 
                    variant="outlined"
                    color="info"
                    sx={{
                        textTransform: "None",
                        backgroundColor: resolution == 6 ? 'lightgrey' : 'None',
                        mr: 0.25
                    }}
                    onClick={() => setResolution(6)}
                    title='Show the worst case value within 6-hour intervals.'
                >
                    6 hours
                </Button>
                <Button 
                    variant="outlined"
                    color="info"
                    sx={{
                        textTransform: "None",
                        backgroundColor: resolution == 12 ? 'lightgrey' : 'None'
                    }}
                    onClick={() => setResolution(12)}
                    title='Show the worst case value within 12-hour intervals.'
                >
                    12 hours
                </Button>
            </Box>

            <div ref={ref}>
                <Typography id="modal-modal-title" variant="h6" component="h2" mb={3}>
                    {props.activityLabel}
                </Typography>

                <Box 
                    sx={{
                        overflowX: 'scroll',
                        width: "100%"
                    }}
                >
                    <table
                        className="mopo-table" 
                        style={{
                            borderSpacing: 'unset',
                        }}
                    >
                        {/* DATES */}
                        <thead>
                            {/* Add empty upper left corner */}
                            <tr>
                                <th
                                    style={{
                                        fontWeight: '700',
                                        position: 'sticky',
                                        left: '0',
                                        zIndex: 1,
                                        background: 'white',
                                    }}
                                >
                                    {`TIME [${props.timezone}]`}
                                </th>
                                {/* liveData */}
                                <th style={{
                                    fontWeight: '700',
                                    textAlign: 'center',
                                    whiteSpace: 'pre-wrap',
                                    minWidth: '35px',
                                    backgroundColor: 'lightgrey'
                                }}>
                                    Live
                                </th>
                                {/* Add dates */}
                                {timestampsAndColspans.map((d) => (
                                    <th
                                        style={{
                                            fontWeight: '700',
                                            textAlign: 'center',
                                            // whiteSpace: 'pre-wrap',
                                            whiteSpace: 'nowrap',
                                            backgroundColor: 'lightgrey',
                                            overflow: "hidden",
                                            
                                        }}
                                        colSpan={d["colspan"]}
                                    >
                                        {moment(d["Timestamp_ms"]).tz(props.timezone).format(d["colspan"] == 1 ? "ddd" : "ddd, DD MMM")}
                                    </th>
                                ))}
                            </tr>

                            {/* TIMESTAMPS */}
                            {/* TIME FROM */}
                            <tr>
                                {/* Add empty upper left corner */}
                                <th
                                    style={{
                                        // minWidth: '150px',
                                        fontWeight: '400',
                                        position: 'sticky',
                                        left: '0',
                                        zIndex: 1,
                                        background: 'white',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    Start of interval
                                </th>
                                {/* liveData */}
                                <th style={{
                                    fontWeight: '400',
                                    textAlign: 'center',
                                    whiteSpace: 'pre-wrap',
                                    minWidth: '35px',
                                    backgroundColor: 'lightgrey'
                                }}>
                                    {
                                        liveDataRange 
                                        ?
                                        moment(liveDataRange["Min"]).tz(props.timezone).format('HH:mm')
                                        :
                                        '-'
                                    }
                                </th>
                                {/* Add timestamps */}
                                {forecastDataResampled[Object.keys(forecastDataResampled)[0]]["Rows"].slice(0, -1).map((data, idx) => (
                                    <th
                                        style={{
                                            fontWeight: '400',
                                            textAlign: 'center',
                                            whiteSpace: 'pre-wrap',
                                            minWidth: '35px',
                                            backgroundColor: 'lightgrey'
                                        }}
                                    >
                                        {moment(data["Timestamp_ms"]).tz(props.timezone).format('HH:mm')}
                                    </th>
                                ))}
                            </tr>     
                            {/* TIME TO */}
                            <tr>
                                {/* Add empty upper left corner */}
                                <th
                                    style={{
                                        // minWidth: '150px',
                                        fontWeight: '400',
                                        position: 'sticky',
                                        left: '0',
                                        zIndex: 1,
                                        background: 'white',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    End of interval
                                </th>
                                {/* liveData */}
                                <th style={{
                                    fontWeight: '400',
                                    textAlign: 'center',
                                    whiteSpace: 'pre-wrap',
                                    minWidth: '35px',
                                    backgroundColor: 'lightgrey'
                                }}>
                                    {
                                        liveDataRange 
                                        ?
                                        moment(liveDataRange["Max"]).tz(props.timezone).format('HH:mm')
                                        :
                                        '-'
                                    }
                                </th>
                                {/* Add timestamps */}
                                {forecastDataResampled[Object.keys(forecastDataResampled)[0]]["Rows"].slice(0, -1).map((data, idx) => (
                                    <th
                                        style={{
                                            fontWeight: '400',
                                            textAlign: 'center',
                                            whiteSpace: 'pre-wrap',
                                            minWidth: '35px',
                                            backgroundColor: 'lightgrey'
                                        }}
                                    >
                                        {moment(forecastDataResampled[Object.keys(forecastDataResampled)[0]]["Rows"][idx+1]["Timestamp_ms"]).tz(props.timezone).format('HH:mm')}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        
                        <tbody>
                            {Object.entries(props.conditionCategorisation).map(([conditionCategoryLabel, parameters]) => {

                                // only render conditionCategory if it has forecastData
                                if (parameters.filter(parameter => parameter.nameForecastData).length > 0) {
                                    return(
                                        <>
                                        {/* Add header per conditionGroup */}
                                        <tr>
                                            <td
                                                style={{
                                                    minWidth: '150px',
                                                    position: 'sticky',
                                                    left: '0',
                                                    zIndex: 1,
                                                    background: 'white'
                                                }}
                                                colSpan={10}
                                            >
                                                <strong>{conditionCategoryLabel}</strong>
                                            </td>
                                        </tr>
                                        {parameters.map((parameter) => {

                                            if (parameter.nameForecastData) {

                                                return (
                                                    // Add parameter and data
                                                    <tr>
                                                        <td 
                                                            style={{
                                                                minWidth: '210px',
                                                                position: 'sticky',
                                                                left: '0',
                                                                zIndex: 1,
                                                                background: 'white',
                                                                paddingLeft: '20px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => props.setSelectedParameter(parameter)}
                                                            title='Open parameter plot'
                                                        >
                                                            {`${parameter.label} [${parameter.unitScaled}]`}
                                                        </td>
                                                        {/* liveData */}
                                                        {
                                                            props.livePermissions[parameter.nameLiveData]
                                                            ?
                                                            (
                                                                props.liveData[parameter.nameLiveData]["isDeprecated"]
                                                                ?
                                                                <td
                                                                    className="mopo-table-condition-col"
                                                                >
                                                                    ///
                                                                </td>
                                                                :
                                                                <td
                                                                    className="mopo-table-condition-col" 
                                                                    data-tag-permission={
                                                                        props.livePermissions[parameter.nameLiveData][0] 
                                                                        ? 
                                                                        props.livePermissions[parameter.nameLiveData][0]["restriction"] 
                                                                        ? 
                                                                        'R' 
                                                                        : 
                                                                        props.livePermissions[parameter.nameLiveData][0]["permitted"] 
                                                                        ? 
                                                                        'Y' 
                                                                        :
                                                                        'N'
                                                                        :
                                                                        formatValue(props.liveData[parameter.nameLiveData]["Value"], parameter)["Value"] == 'NaN'
                                                                        ?
                                                                        ''
                                                                        :
                                                                        'Y'
                                                                    }
                                                                    data-tag-current={true}
                                                                >
                                                                    {formatValue(props.liveData[parameter.nameLiveData]["Value"], parameter)["Value"] == 'NaN' ? '-' : formatValue(props.liveData[parameter.nameLiveData]["Value"], parameter)["Value"]}
                                                                </td>
                                                            )
                                                            :
                                                            <td
                                                                className="mopo-table-condition-col"
                                                            >
                                                                -
                                                            </td>
                                                        }
                                                        {forecastDataResampled[parameter.nameForecastData]["Rows"].slice(0, -1).map((data, idx) => (
                                                            <td
                                                                className="mopo-table-condition-col" 
                                                                data-tag-permission={
                                                                    forecastPermissionsResampled[parameter.nameForecastData][idx] 
                                                                    ? 
                                                                    forecastPermissionsResampled[parameter.nameForecastData][idx]["restriction"] 
                                                                    ? 
                                                                    'R' 
                                                                    : 
                                                                    forecastPermissionsResampled[parameter.nameForecastData][idx]["permitted"] 
                                                                    ? 
                                                                    'Y' 
                                                                    :
                                                                    'N'
                                                                    :
                                                                    formatValue(data["Value"], parameter)["Value"] == 'NaN'
                                                                    ?
                                                                    ''
                                                                    :
                                                                    'Y'
                                                                }
                                                                data-tag-current={true}
                                                            >
                                                                {formatValue(data["Value"], parameter)["Value"] == 'NaN' ? '-' : formatValue(data["Value"], parameter)["Value"]}
                                                            </td>                                                            
                                                        ))
                                                        }
                                                    </tr>
                                                )
                                            }
                                        })}
                                        </>
                                    )
                                }
                            })}
                        </tbody>
                    </table>
                </Box>
            </div>
        </>
    );
})

export default Forecast;
