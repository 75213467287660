import "../../../style.css";

import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layout";
import { ForecastTable } from '../../components/PDFForecasts/ForecastTable';
import { Alert, Backdrop, Box, Button, CircularProgress, Grid, IconButton, Snackbar } from "@mui/material";
import { Editor } from "../../components/PDFForecasts/Editor";
import { GetObjectCommand, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { AuthContext } from "../../contexts/authContext";



interface IMainSnack {
    severity: string;
    message: string;
    timeout?: number;
}

export interface IOptions {
    checkboxSize: number;
}

interface Props {
    options?: IOptions
}



export const PDFForecastsConfig = (props: Props): React.ReactElement => {

    const defaultOptions: IOptions = {
        checkboxSize: 20,
    }

    const { ensureAwsCredentials, sessionInfo } = useContext(AuthContext);

    const [dirty, setDirty] = React.useState(false);

    const [modalOpen, setModalOpen] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [rowToEdit, setRowToEdit] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [snack, setSnack] = React.useState<IMainSnack>(null);
    const [snackOpen, setSnackOpen] = React.useState<boolean>(false);

    const DEFAULT_SNACK_TIMEOUT = 6000;

    // console.info('PDFForecasts.Main... ', [ dirty, modalOpen, rowToEdit, rows ]);

    React.useEffect(() => { //: see: https://dev.to/fromwentzitcame/working-with-tables-in-react-how-to-render-and-edit-fetched-data-5fl5

        const handleGetData = async () => {

            console.log('[handleGetData] Getting data...')

            const awsConfig = await ensureAwsCredentials();

            const s3Client = new S3Client({
                credentials: awsConfig.credentials,
                region: awsConfig.region
            });

            try {
                const response = await s3Client.send(
                    new GetObjectCommand({
                        Bucket: "shell-dw-module-output",
                        Key: "modules/dwforecasts--tests/config/stations.json",
                        ResponseCacheControl: "no-cache"
                    })
                );

                if (response.$metadata.httpStatusCode != 200) {
                    setSnack({ severity: 'error', message: `Error while retrieving stations config.`, timeout: null });
                    setSnackOpen(true);
                    setIsLoading(false);
                    throw new Error("Network response was not OK");
                } 

                const a = await response.Body.transformToString()
                const data = JSON.parse(a).stations;

                setRows(data);
                setIsLoading(false);

            } catch (error) {
                console.warn('Retrieving the config failed:', error);
                setRows([]);
                setSnack({ 
                    severity: 'error', 
                    message: `Error while retrieving stations config.`, 
                    timeout: null 
                });
                setSnackOpen(true);
                setIsLoading(false);
            }
        }

        handleGetData();
        
    }, []);

    const handleSetDirty = () => {
        setDirty(true);
        setSnackOpen(false); //: default hiding of any snacks in case for/table become dirty
        //: TODO: see if possible to add page navigation or tabbed content tab-change prevention..
    };

    const handleDeleteRow = (targetIndex) => {
        setRows(rows.filter((_, idx) => idx !== targetIndex));
        handleSetDirty();
    };

    const handleEditRow = (idx) => {
        setRowToEdit(idx);
        setModalOpen(true);
    };

    const handleSubmit = (newRow) => {
        rowToEdit === null
            ? setRows([...rows, newRow])
            : setRows(
                rows.map((currRow, idx) => {
                    if (idx !== rowToEdit) return currRow;
                    return newRow;
                })
            );

        handleSetDirty();
    };

    const handleSaveData = async () => {
        
        setIsLoading(true);

        const awsConfig = await ensureAwsCredentials();

        const s3Client = new S3Client({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        // The backend expects different keys than those used here. Hence, a conversion is needed.
        // All keys are kept such that another conversion is not neccessary when loading back here.
        const backendCompatibleConfig = { 
            "stations": rows.map(row => { 
                return (
                    {
                        ...row, 
                        "on": row.Active? "yes" : "no",
                        "name": row.StationName,
                        "issueTimesUTC": row.UTCIssueTimes,
                        "latitude": parseFloat(row.Latitude),
                        "longitude": parseFloat(row.Longitude),
                        "timezone": parseInt(row.TimezoneOffset),
                        "type": row.Type,
                        "forecastDays":  parseInt(row.DaysForecast),
                        "emails": row.RecipientsEmails,
                        "outfolder": "/home/jasond/dwForecasts/data/"
                    }
                )
            })
        }

        try {

            const response = await s3Client.send(
                new PutObjectCommand({
                    Bucket: "shell-dw-module-output",
                    Key: "modules/dwforecasts--tests/config/stations.json",
                    // Body: JSON.stringify(rows)
                    Body: JSON.stringify(backendCompatibleConfig)
                })
            );
    
            if (response.$metadata.httpStatusCode != 200) {
                setSnack({ severity: 'error', message: `Error while saving stations config.`, timeout: null });
                setSnackOpen(true);
                setIsLoading(false);
                throw new Error("Network response was not OK");
            } 
    
            setIsLoading(false);
            setDirty(false);
            setSnack({ severity: 'success', message: `The configuration was saved.`, timeout: DEFAULT_SNACK_TIMEOUT });
            setSnackOpen(true);
        } catch (error) {
            console.warn('Saving the config failed:', error);
            setSnack({
                severity: 'error',
                message: `Error while saving stations config.`,
                timeout: null
            });
            setIsLoading(false);
            setSnackOpen(true);
        }
    };

    const handleCloseBackdrop = (event: React.SyntheticEvent | Event, reason?: string) => {
        console.info('handleCloseBackdrop - reason=', reason);
        if (reason === 'clickaway') {
            return;
        }
        setIsLoading(false);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <Layout moduleName="PDF Forecasts"> 
            <Backdrop
                //: TODO: ?? try making this only wrapper element, not full page, to stop blocking entire web-app: https://stackoverflow.com/a/63310600
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={handleCloseBackdrop}
                // onClose={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {snack &&
                <Snackbar
                    open={snackOpen}
                    onClose={handleSnackClose}
                    autoHideDuration={snack.timeout}
                >
                    <Alert variant="filled" severity={snack.severity} sx={{ width: '100%' }}>
                        <span>{snack.message}</span>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleSnackClose}
                        >
                            <i className="fa fa-fw fa-lg fa-times"></i>
                        </IconButton>
                    </Alert>
                </Snackbar>
            }
            <Box sx={{ width: '100%', pl: 1 }}>
                <Box sx={{ flexGrow: 1, mb: 2 }}>
                    <Grid container>
                        <Grid xs={8}>
                            <h4 className="no-margin">PDF Forecasts</h4>
                        </Grid>
                        <Grid xs={4} sx={{ textAlign: 'right' }}>
                            <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
                                <i className="fa fa-fw fa-plus"></i>
                                <span>Add</span>
                            </Button>
                            <span>&nbsp;</span>
                            <Button variant="contained" color="success" disabled={!dirty} onClick={handleSaveData} /*startIcon={<DeleteIcon />}*/>
                                <i className="fa fa-fw fa-check"></i>
                                <span>Save</span>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <ForecastTable rows={rows} editRow={handleEditRow} deleteRow={handleDeleteRow} options={defaultOptions}></ForecastTable>
                {modalOpen && (
                    <Editor closeModal={() => { setModalOpen(false); setRowToEdit(null); }} onSubmit={handleSubmit} defaultValue={rowToEdit !== null && rows[rowToEdit]} options={defaultOptions}></Editor>
                )}
            </Box>
        </Layout>
    )
};
