import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { IOptions } from "../../routes/pdf-forecasts/PDFForecastsConfig";
import { Row } from "./Row";



export interface IPDFForecastsRowData {
    StationName: string;
    Latitude: number;
    Longitude: number;
    Active: boolean;
    TimezoneOffset: number;
    Type: string;
    UTCIssueTimes: number[];
    DaysForecast: number;
    Units: "Metric" | "US";
    RecipientsEmails: string[];
}

// export const blankPDFForecastRowData: PDFForecastsRowData = {
//     StationName: "",
//     Latitude: null,
//     Longitude: null,
//     Active: true,
//     TimezoneOffset: 0,
//     Type: 'ocean',
//     UTCIssueTimes: [],
//     DaysForecast: 1,
//     RecipientsEmails: [],
// };


interface Props {
    rows: IPDFForecastsRowData[],
    deleteRow: any,
    editRow: any,
    options: IOptions
}

export const ForecastTable = (props: Props) => {
    //: inspired from: https://www.youtube.com/watch?v=MINQoCSBmds  &&  https://github.com/CodeCompleteYT/react-table/blob/main/src/components/Table.jsx

    return (
        <>
        <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Station Name</TableCell>
                            <TableCell align="center">Latitude</TableCell>
                            <TableCell align="center">Longitude</TableCell>
                            <TableCell align="center">Active</TableCell>
                            <TableCell align="center">Timezone Offset</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">UTC Issue Times</TableCell>
                            <TableCell align="center">Days Forecast</TableCell>
                            <TableCell align="center">Recipient(s) Email(s)</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.rows && props.rows.length > 0 ?
                                props.rows.map((row, i) => <Row key={`row-${i}`} data={row} idx={i} editRow={props.editRow} deleteRow={props.deleteRow} options={props.options}></Row>)
                                :
                                <TableRow
                                    // key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" colSpan={10} align="center" sx={{ color: 'text.disabled', p: 3 }}>
                                        <span>No data</span>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </Box>
        </Box>
        </>
    )
}