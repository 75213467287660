import { IActivity, IMopoSpecifications, IPermission } from "../types/IMopo";
import { useState } from "react";
import "../../style.css"
import { IconButton, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getLiveDataRange, getParametersFromTest } from "../libs/mopo";
import { IConditionCategorisation } from "../types/IWeatherWindow";
import { ILiveData } from "../types/ILiveData";
import moment from "moment-timezone";

interface Props {
    mopoSpecification?: IMopoSpecifications;
    liveData?: ILiveData;
    setSelectedActivity: (filter: IActivity | undefined) => void,
    conditionCategorisation: IConditionCategorisation,
    timezone: string,
}

export const MopoTable = (props: Props) => {

    const [expandedActivityCategories, setExpandedActivityCategories] = useState<string[]>(props.mopoSpecification.activityCategories.map((activityCategory) => activityCategory.label));

    const onActivityCategoryLabelClick = (label: string) => {

        const idx = expandedActivityCategories.indexOf(label);

        if (idx > -1) {
            // if label is already in expandedActivityCategories, remove it
            setExpandedActivityCategories(expandedActivityCategories.filter((activityCategory, idx) => activityCategory != label))
        } else {
            // if label is not in expandedActivityCategories, add it
            setExpandedActivityCategories([...expandedActivityCategories, label])
        }

    }

    const liveDataRange = getLiveDataRange(props.liveData, props.conditionCategorisation);

    //// Get appliedConditions and fetchedConditions (conditions for which data has been fetched)

    let appliedConditionIds: string[] = [];
    let fetchedConditionIds: string[] = [];

    props.mopoSpecification.conditionCategories.map(
        (conditionCategory) => (conditionCategory.conditions.map( 
            (condition) => {

                // appliedConditions
                const testFunction = eval(condition.test)

                if (testFunction(props.liveData)) {
                    appliedConditionIds = [...appliedConditionIds, condition.conditionId]
                }

                // fetchedConditions
                const parametersFromTest = new Set(getParametersFromTest(condition.test));
                const parameterIntersection = parametersFromTest.intersection(new Set(Object.keys(props.liveData)))

                if (parametersFromTest.size > 0 && parameterIntersection.size == parametersFromTest.size) {
                    fetchedConditionIds = [...fetchedConditionIds, condition.conditionId]
                }
            }
        ))
    )

    console.log('[INFO] appliedConditionIds: ', appliedConditionIds)
    console.log('[INFO] fetchedConditionIds: ', fetchedConditionIds)

    // Get worst permission per condition
    // clone
    let worstPermissionPerCondition: IPermission[] = props.mopoSpecification.activityCategories[0].activities[0].permissions.map((x) => x)

    props.mopoSpecification.activityCategories.map((activityCategory) => 
        activityCategory.activities.map((activity) => {
            worstPermissionPerCondition.map((worstPermission, idx) => {
                // compare permission currently stored as worst with all others

                if (worstPermission.permitted && (!activity.permissions[idx].permitted || activity.permissions[idx].restriction)) {
                    // replace
                    worstPermissionPerCondition[idx] = activity.permissions[idx]
                } else if (worstPermission.restriction && !activity.permissions[idx].permitted) {
                    // replace
                    worstPermissionPerCondition[idx] = activity.permissions[idx]
                }
            })
        })
    )

    console.log('[INFO] worstPermissionPerCondition: ', worstPermissionPerCondition)

    return (
        <table 
            className="mopo-table" 
            style={{
                borderSpacing: 'unset',
                margin: 'auto'
            }}
        >
            {/* Populate conditionCategories */}
            <thead 
                style={{
                    position: 'sticky', 
                    top: 0, 
                    backgroundColor: 'white',
                    zIndex: 1
                }}
            >
                <tr>
                    <th colSpan={2}></th>
                    <th colSpan={34} style={{textAlign: 'center'}}>
                        CONDITIONS
                    </th>
                </tr>
                <tr>
                    <th 
                        colSpan={2}
                        style={{
                            textAlign: 'center',
                            fontWeight: 400,
                            backgroundColor: 'white',
                        }}
                    >
                        {`The applied data is measured between ${moment(liveDataRange["Min"]).tz(props.timezone).format("HH:mm")} - ${moment(liveDataRange["Max"]).tz(props.timezone).format("HH:mm ddd, DD MMM")} [${props.timezone}].`}<br/>
                        <br/>
                        The column of an applied condition is highlighted.<br/>
                        <br/>
                        All activity labels are colored according to the most limiting permission in that row.<br/>
                        <br/>
                        If the text in a column is greyed out, data is not available to check that condition.
                    </th>
                    {props.mopoSpecification.conditionCategories.map((conditionCategory, idx) => {
                        return (
                            <>
                            <th 
                                key={idx}
                                className='mopo-table-condition-col' 
                                style={{
                                    writingMode: 'vertical-rl', 
                                    transform: 'rotate(180deg)', 
                                    fontWeight: '400',
                                    // height: '215px',
                                    height: '255px',
                                    textAlign: 'left',
                                    lineHeight: '0.8em'
                                }}
                            >
                                <strong>{conditionCategory.label}</strong>
                            </th>
                            {conditionCategory.conditions.map((condition, idx) =>
                                <th 
                                    key={idx}
                                    className='mopo-table-condition-col' 
                                    style={{
                                        writingMode: 'vertical-rl', 
                                        transform: 'rotate(180deg)', 
                                        fontWeight: '400',
                                        textAlign: 'left',
                                        // lineHeight: '0.8em',
                                        // fontSize: '10.5px'
                                    }}
                                    data-tag-appliedcondition={appliedConditionIds.includes(condition.conditionId)}
                                    // data-tag-worstappliedconditiontype={
                                    //     // don't colorcode conditions. NB: issue with coloring. probably NA leads to 'N' below.
                                    //     worstPermissionPerCondition[idx].restriction? 'R' : worstPermissionPerCondition[idx].permitted? 'Y' : 'N'
                                    // }
                                    data-is-fetched={fetchedConditionIds.includes(condition.conditionId)}
                                >
                                    <Tooltip 
                                        arrow  
                                        title={
                                            <>
                                            <Typography sx={{ fontStyle: 'italic' }}>
                                                Has data: {fetchedConditionIds.includes(condition.conditionId) ? 'Yes' : 'No'}
                                                <br/>
                                                Is current: {appliedConditionIds.includes(condition.conditionId) ? 'Yes' : 'No'}
                                                <br/>
                                                <br/>
                                            </Typography>
                                            <Typography>
                                                {condition.longLabel}
                                            </Typography>
                                            </>
                                        }
                                        slotProps={{
                                            popper: {
                                            modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, -10],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                    >
                                        <div style={{ cursor: 'pointer'  }}>
                                            {condition.label}
                                        </div>
                                    </Tooltip>
                                </th>
                            )}
                            </>
                        );
                    })}
                </tr>
                <tr>
                    <th 
                        colSpan={36} 
                        style={{
                            height: '5px'
                        }}
                    >
                    </th>
                </tr>
            </thead>

            <tbody style={{overflow: 'scroll'}}>
            {/* Populate each activity and its permissions */}
            {props.mopoSpecification.activityCategories.map((activityCategory) => {

                return (
                    <>
                    <tr>
                        <td
                            colSpan={2}
                        >
                            <strong>{activityCategory.label}</strong>
                            {/* Button for expansion/collaps of activityCategories */}
                            <IconButton onClick={(e) => onActivityCategoryLabelClick(activityCategory.label)}>
                                {expandedActivityCategories.indexOf(activityCategory.label) > -1 ? 
                                    <ExpandMoreIcon /> 
                                    : 
                                    <NavigateNextIcon />
                                }
                            </IconButton>
                        </td>
                    </tr>
                    {activityCategory.activities.map((activity) => {

                        const appliedPermissions = activity.permissions.filter(
                            (permission, idx) => appliedConditionIds.includes(permission.conditionId)
                        );

                        let worstAppliedPermission: string;

                        if (appliedPermissions.filter((appliedPermission, idx) => appliedPermission.permitted == false).length > 0) {
                            // if any appliedPermission is false / 'N'
                            worstAppliedPermission = 'N'
                        } else if (appliedPermissions.filter((appliedPermission, idx) => appliedPermission.restriction != null).length > 0) {
                            // if any appliedPermission is restricted / 'R*'
                            worstAppliedPermission = 'R'
                        } else {
                            worstAppliedPermission = 'Y'
                        }

                        // Collapsable activityCategories
                        if (expandedActivityCategories.includes(activityCategory.label)) {

                            // Get activityDescription
                            const activityDescriptionOi = props.mopoSpecification.activityDescriptions.filter((activityDescription, idx) => activityDescription.label == activity.label)[0]

                            return (
                                <tr>
                                    <td 
                                        className='mopo-table-activity-id'
                                        data-tag-worstappliedpermission={worstAppliedPermission}
                                    >
                                        {activity.id}
                                    </td>                                    
                                    <td 
                                        className='mopo-table-activity-label'
                                        data-tag-worstappliedpermission={worstAppliedPermission}
                                        onClick={() => props.setSelectedActivity(activity)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Tooltip 
                                            arrow  
                                            title={
                                                <Typography>
                                                    {activityDescriptionOi?.description}
                                                </Typography>
                                            }
                                            slotProps={{
                                                popper: {
                                                modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, -10],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}
                                        >
                                            <div style={{ cursor: 'pointer'  }}>
                                                {activity.label}
                                            </div>
                                        </Tooltip>
                                    </td>
                                    
                                    {props.mopoSpecification.conditionCategories.map((conditionCategory) => {

                                        return (
                                            <>
                                            <th></th>
                                            {conditionCategory.conditions.map((condition) => {

                                                const conditionOi = activity.permissions.filter((element, idx) => element.conditionId == condition.conditionId)[0]

                                                // Get restrictionDescription
                                                const restrictionDescriptionOi = props.mopoSpecification.restrictionDescriptions.filter(
                                                    (restrictionDescription, idx) => restrictionDescription.id == conditionOi.restriction
                                                )[0]?.description

                                                return (
                                                    <>
                                                        {conditionOi.restriction? 
                                                            <Tooltip 
                                                                arrow  
                                                                title={
                                                                    <Typography style={{whiteSpace: 'pre-wrap'}}>
                                                                        {restrictionDescriptionOi}
                                                                    </Typography>
                                                                }
                                                                slotProps={{
                                                                    popper: {
                                                                        modifiers: [
                                                                            {
                                                                                name: 'offset',
                                                                                options: {
                                                                                    offset: [0, -10],
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            >
                                                                <td 
                                                                    className="mopo-table-condition-col" 
                                                                    data-tag-permission={'R'}
                                                                    data-tag-current={appliedConditionIds.includes(conditionOi.conditionId)}
                                                                    data-is-fetched={fetchedConditionIds.includes(conditionOi.conditionId)}
                                                                >
                                                                    {conditionOi.restriction}
                                                                </td>
                                                            </Tooltip>
                                                            : 
                                                            conditionOi.permitted == true? 
                                                            <td 
                                                                className="mopo-table-condition-col" 
                                                                data-tag-permission={'Y'}
                                                                data-tag-current={appliedConditionIds.includes(conditionOi.conditionId)}
                                                                data-is-fetched={fetchedConditionIds.includes(conditionOi.conditionId)}
                                                            >
                                                                {'Y'} 
                                                            </td>
                                                            : 
                                                            conditionOi.permitted == false? 
                                                            <td 
                                                                className="mopo-table-condition-col" 
                                                                data-tag-permission={'N'}
                                                                data-tag-current={appliedConditionIds.includes(conditionOi.conditionId)}
                                                                data-is-fetched={fetchedConditionIds.includes(conditionOi.conditionId)}
                                                            >
                                                                {'N'} 
                                                            </td>
                                                            :
                                                            <td
                                                                className="mopo-table-condition-col" 
                                                                data-is-fetched={fetchedConditionIds.includes(conditionOi.conditionId)}
                                                            >
                                                                {'NA'}
                                                            </td>
                                                        }
                                                    </>
                                                );
                                            })}
                                            </>
                                        );
                                    })}
                                </tr>
                            );
                        }
                    })}
                    </>
                );
            })}
        </tbody>
        </table>
    );
}