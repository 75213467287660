{
    "activityCategories": [
        {
            "label": "PRODUCTION OPERATIONS",
            "activities": [
                {
                    "id": "A1",
                    "label": "Shutdown (boilers offline or running on diesel)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "A2",
                    "label": "Shutdown (boilers running on fuel gas)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "A3",
                    "label": "Shutdown and depressurised (e.g. post-emergency depressurisation)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "A4",
                    "label": "Upstream in operation. Warm and cold end shutdown and depressurised",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "A5",
                    "label": "Upstream and warm end in operation",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "A8",
                    "label": "EDP/ESD Testing",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                }
            ]
        },
        {
            "label": "MAINTENANCE",
            "activities": [
                {
                    "id": "B1",
                    "label": "High risk hot work on Prelude ",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B2",
                    "label": "High risk hot work inside FLNG 1500m safety zone (exposed flame)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R26"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B3",
                    "label": "Medium risk hot work ",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": "R8"
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": "R8"
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": "R8"
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B4",
                    "label": "Breaking into hydrocarbon systems",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B5",
                    "label": "Breaking into hydrocarbon systems (condensate reel/hose after the blast wall)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B6",
                    "label": "Gas freeing and inert gas operations",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B7",
                    "label": "Flare boom access",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "M",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AF",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B8",
                    "label": "Confined space entry",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B9",
                    "label": "Leak testing",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B10",
                    "label": "Work over the side",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R30"
                        },
                        {
                            "conditionId": "M",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AE",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B11",
                    "label": "Remotely piloted airborne systems (RPAS)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AF",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B12",
                    "label": "Removal of water intake riser/large machinery space hatches",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B13",
                    "label": "Removal of large machinery space hatches.",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B14",
                    "label": "Removal of the forward starboard hatch",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "B15",
                    "label": "Removal of the thruster hatch",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                }
            ]
        },
        {
            "label": "TRANSPORT / SUPPLY / BARGE",
            "activities": [
                {
                    "id": "C1",
                    "label": "Non-routine lifts",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R18"
                        },
                        {
                            "conditionId": "M",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "C2",
                    "label": "Thruster operations, including thruster testing",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "C3",
                    "label": "Routine crane operations starboard side ",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R18"
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": "R18"
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R18"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "C4",
                    "label": "Routine crane operations port side ",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R18"
                        },
                        {
                            "conditionId": "M",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "C5",
                    "label": "Helicopter movement, take off and landing from Prelude",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": "R17"
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": "R17"
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R16"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": null,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "C6 C7",
                    "label": "Supply boat loading/unloading including bunkering starboard",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R18"
                        },
                        {
                            "conditionId": "M",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AE",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "C7 C8",
                    "label": "Supply boat loading/unloading including bunkering port",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R18"
                        },
                        {
                            "conditionId": "M",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AE",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "C8 C9",
                    "label": "Entry of ancillary vessel within the 1500m FLNG safety zone",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "C9 C10",
                    "label": "Personnel transfer to/from facility by side watertight doors or aft bow catcher",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "C10 C11",
                    "label": "Personnel transfer via the FROG",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "L",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "AE",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": "R4"
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R27"
                        }
                    ]
                },
                {
                    "id": "C11 C12",
                    "label": "LNG/LPG tanker berthing (side-by-side)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "H",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "M",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R27"
                        }
                    ]
                },
                {
                    "id": "C12 C13",
                    "label": "LNG/LPG tanker offloading (side-by-side)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R72"
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": "R28"
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R28"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "AK",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R27"
                        }
                    ]
                },
                {
                    "id": "C13 C14",
                    "label": "LNG/LPG tanker unberthing (side-by-side)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R27"
                        }
                    ]
                },
                {
                    "id": "C14 C15",
                    "label": "Condensate tanker mooring (tandem)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R28"
                        },
                        {
                            "conditionId": "M",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R27"
                        }
                    ]
                },
                {
                    "id": "C15 C16",
                    "label": "Condensate tanker offloading (tandem)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R72"
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R28"
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": "R29"
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R29"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": "R25"
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R27"
                        }
                    ]
                },
                {
                    "id": "C16 C17",
                    "label": "Condensate tanker unberthing (tandem)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R27"
                        }
                    ]
                }
            ]
        },
        {
            "label": "WELLS & SUBSEA MAINTENANCE",
            "activities": [
                {
                    "id": "D1",
                    "label": "Wells and flowline commissioning",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": "R11"
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "D2",
                    "label": "Breaking into hydrocarbon system subsea",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "D3",
                    "label": "Well intervention activities ",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": null
                        }
                    ]
                },
                {
                    "id": "D4",
                    "label": "ROV activities within the FLNG safety zone",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "I",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "M",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AA",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": "R1"
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": "R1"
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": "R1"
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R1"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": "R1"
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R1"
                        }
                    ]
                },
                {
                    "id": "D5",
                    "label": "ROV activities outside the FLNG safety zone",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R15"
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": "R15"
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R12"
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": "R15"
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R15"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AA",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": "R15"
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": "R15"
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": "R13"
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": "R1"
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": "R1"
                        },
                        {
                            "conditionId": "AK",
                            "permitted": true,
                            "restriction": "R1"
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R1"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": true,
                            "restriction": "R1"
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R1"
                        }
                    ]
                }
            ]
        },
        {
            "label": "TURNAROUND",
            "activities": [
                {
                    "id": "E1",
                    "label": "ASV Connected PORT (Rotated 45 deg AFT or TRANSVERSE)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": "R101"
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R101"
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": "R101"
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": "R92"
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R102"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R103"
                        }
                    ]
                },
                {
                    "id": "E2",
                    "label": "ASV Connected AFT (Rotated 30 or 40 deg STBD as required)",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": "R101"
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R101"
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": "R101"
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": "R92"
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R102"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R103"
                        }
                    ]
                },
                {
                    "id": "E3",
                    "label": "ASV receiving cargo/bunkering operations whilst connected",
                    "permissions": [
                        {
                            "conditionId": "G",
                            "permitted": true,
                            "restriction": "R95"
                        },
                        {
                            "conditionId": "H",
                            "permitted": true,
                            "restriction": "R95"
                        },
                        {
                            "conditionId": "I",
                            "permitted": true,
                            "restriction": "R95"
                        },
                        {
                            "conditionId": "K",
                            "permitted": true,
                            "restriction": "R95"
                        },
                        {
                            "conditionId": "L",
                            "permitted": true,
                            "restriction": "R95"
                        },
                        {
                            "conditionId": "M",
                            "permitted": true,
                            "restriction": "R95"
                        },
                        {
                            "conditionId": "N",
                            "permitted": true,
                            "restriction": "R95"
                        },
                        {
                            "conditionId": "O",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "P",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "Q",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "R",
                            "permitted": false,
                            "restriction": null
                        },
                        {
                            "conditionId": "T",
                            "permitted": true,
                            "restriction": "R96"
                        },
                        {
                            "conditionId": "V",
                            "permitted": true,
                            "restriction": "R92"
                        },
                        {
                            "conditionId": "X",
                            "permitted": true,
                            "restriction": "R97"
                        },
                        {
                            "conditionId": "Y",
                            "permitted": true,
                            "restriction": "R97"
                        },
                        {
                            "conditionId": "Z",
                            "permitted": true,
                            "restriction": "R97"
                        },
                        {
                            "conditionId": "AA",
                            "permitted": true,
                            "restriction": "R97"
                        },
                        {
                            "conditionId": "AD",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AE",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AF",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AG",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AI",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AJ",
                            "permitted": true,
                            "restriction": null
                        },
                        {
                            "conditionId": "AK",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AL",
                            "permitted": true,
                            "restriction": "R102"
                        },
                        {
                            "conditionId": "AM",
                            "permitted": null,
                            "restriction": null
                        },
                        {
                            "conditionId": "AO",
                            "permitted": true,
                            "restriction": "R103"
                        }
                    ]
                }
            ]
        }
    ],
    "conditionCategories": [
        {
            "label": "WAVE HEIGHT",
            "conditions": [
                {
                    "conditionId": "G",
                    "label": "Wave height and swell (HS) 0 to 2.5m",
                    "longLabel": "Wave height and swell (HS) 0 to 2.5m",
                    "test": "d => d[\"Hs_m\"][\"Value\"] >= 0.0 && d[\"Hs_m\"][\"Value\"] < 2.5",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "Hs_m",
                            "test": "d => d[\"Hs_m\"][\"Value\"] >= 0.0 && d[\"Hs_m\"][\"Value\"] < 2.5"
                        }
                    ]
                },
                {
                    "conditionId": "H",
                    "label": "Wave height and wwell (HS) >2.5 to 3.0m",
                    "longLabel": "Wave height and wwell (HS) >2.5 to 3.0m",
                    "test": "d => d[\"Hs_m\"][\"Value\"] >= 2.5 && d[\"Hs_m\"][\"Value\"] < 3.0",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "Hs_m",
                            "test": "d => d[\"Hs_m\"][\"Value\"] >= 2.5 && d[\"Hs_m\"][\"Value\"] < 3.0"
                        }
                    ]
                },
                {
                    "conditionId": "I",
                    "label": "Wave height and swell (HS) > 3.0m",
                    "longLabel": "Wave height and swell (HS) > 3.0m",
                    "test": "d => d[\"Hs_m\"][\"Value\"] >= 3.0",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "Hs_m",
                            "test": "d => d[\"Hs_m\"][\"Value\"] >= 3.0"
                        }
                    ]
                }
            ]
        },
        {
            "label": "MEAN WIND SPEED (10-minute)",
            "conditions": [
                {
                    "conditionId": "K",
                    "label": "Mean wind speed 0 to <15 knots",
                    "longLabel": "Mean wind speed 0 to <15 knots",
                    "test": "d => d[\"WindSpeed_kts\"][\"Value\"] >= 0 && d[\"WindSpeed_kts\"][\"Value\"] < 15",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "WindSpeed_kts_10m",
                            "test": "d => d[\"WindSpeed_kts_10m\"][\"Value\"] >= 0 && d[\"WindSpeed_kts_10m\"][\"Value\"] < 15"
                        }
                    ]
                },
                {
                    "conditionId": "L",
                    "label": "Mean wind speed 15 to <25 knots",
                    "longLabel": "Mean wind speed 15 to <25 knots",
                    "test": "d => d[\"WindSpeed_kts\"][\"Value\"] >= 15 && d[\"WindSpeed_kts\"][\"Value\"] < 25",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "WindSpeed_kts_10m",
                            "test": "d => d[\"WindSpeed_kts_10m\"][\"Value\"] >= 15 && d[\"WindSpeed_kts_10m\"][\"Value\"] < 25"
                        }
                    ]
                },
                {
                    "conditionId": "M",
                    "label": "Mean wind speed 25 to <28 knots",
                    "longLabel": "Mean wind speed 25 to <28 knots",
                    "test": "d => d[\"WindSpeed_kts\"][\"Value\"] >= 25 && d[\"WindSpeed_kts\"][\"Value\"] < 28",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "WindSpeed_kts_10m",
                            "test": "d => d[\"WindSpeed_kts_10m\"][\"Value\"] >= 25 && d[\"WindSpeed_kts_10m\"][\"Value\"] < 28"
                        }
                    ]
                },
                {
                    "conditionId": "N",
                    "label": "Mean wind speed  28 to <34 knots",
                    "longLabel": "Mean wind speed  28 to <34 knots",
                    "test": "d => d[\"WindSpeed_kts\"][\"Value\"] >= 28 && d[\"WindSpeed_kts\"][\"Value\"] < 34",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "WindSpeed_kts_10m",
                            "test": "d => d[\"WindSpeed_kts_10m\"][\"Value\"] >= 28 && d[\"WindSpeed_kts_10m\"][\"Value\"] < 34"
                        }
                    ]
                },
                {
                    "conditionId": "O",
                    "label": "Cyclone Category 1 \u2265 34 knots",
                    "longLabel": "Cyclone Category 1 \u2265 34 knots (restricted personnel outside LQ and substructure)",
                    "test": "d => d[\"WindSpeed_kts\"][\"Value\"] >= 34 && d[\"WindSpeed_kts\"][\"Value\"] < 47",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "WindSpeed_kts_10m",
                            "test": "d => d[\"WindSpeed_kts_10m\"][\"Value\"] >= 34 && d[\"WindSpeed_kts_10m\"][\"Value\"] < 47"
                        }
                    ]
                },
                {
                    "conditionId": "P",
                    "label": "Cyclone Category 2 \u2265 47 knots",
                    "longLabel": "Cyclone Category 2 \u2265 47 knots (restricted personnel outside LQ and substructure)",
                    "test": "d => d[\"WindSpeed_kts\"][\"Value\"] >= 47 && d[\"WindSpeed_kts\"][\"Value\"] < 64",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "WindSpeed_kts_10m",
                            "test": "d => d[\"WindSpeed_kts_10m\"][\"Value\"] >= 47 && d[\"WindSpeed_kts_10m\"][\"Value\"] < 64"
                        }
                    ]
                },
                {
                    "conditionId": "Q",
                    "label": "Cyclone Category 3 \u2265 64 knots",
                    "longLabel": "Cyclone Category 3 \u2265 64 knots (restricted personnel outside LQ and substructure)",
                    "test": "d => d[\"WindSpeed_kts\"][\"Value\"] >= 64 && d[\"WindSpeed_kts\"][\"Value\"] < 85",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "WindSpeed_kts_10m",
                            "test": "d => d[\"WindSpeed_kts_10m\"][\"Value\"] >= 64 && d[\"WindSpeed_kts_10m\"][\"Value\"] < 85"
                        }
                    ]
                },
                {
                    "conditionId": "R",
                    "label": "Cyclone Category 4 or 5, > 85 knots",
                    "longLabel": "Cyclone Category 4 or 5, > 85 knots (restricted personnel outside LQ and substructure)",
                    "test": "d => d[\"WindSpeed_kts\"][\"Value\"] >= 85",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "WindSpeed_kts_10m",
                            "test": "d => d[\"WindSpeed_kts_10m\"][\"Value\"] >= 85"
                        }
                    ]
                }
            ]
        },
        {
            "label": "NIGHT OPERATIONS",
            "conditions": [
                {
                    "conditionId": "T",
                    "label": "Work being conducted after sunset and before sunrise",
                    "longLabel": "Work being conducted after sunset and before sunrise",
                    "test": "d => d[\"Daylight_bool\"][\"Value\"] == 0",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "Daylight_bool",
                            "test": "d => d[\"Daylight_bool\"][\"Value\"] == 0"
                        }
                    ]
                }
            ]
        },
        {
            "label": "CLIMATE CONDITIONS",
            "conditions": [
                {
                    "conditionId": "V",
                    "label": "Facility enters lightning Red alert phase",
                    "longLabel": "Facility enters lightning Red alert phase (as per Prelude Cyclone and Adverse Weather Procedure OPS_PRE_005391)",
                    "test": "d => false",
                    "forecastConditions": [
                        {
                            "parameterGroupId": null,
                            "test": "d => false"
                        }
                    ]
                }
            ]
        },
        {
            "label": "TIDAL CURRENT",
            "conditions": [
                {
                    "conditionId": "X",
                    "label": "Surface current 0 to 0.8 knots",
                    "longLabel": "Surface current 0 to 0.8 knots",
                    "test": "d => d[\"Total_CurrentSpeed_Surface_mps_Mean\"][\"Value\"] > 0 && d[\"Total_CurrentSpeed_Surface_mps_Mean\"][\"Value\"] < 0.8",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "Total_CurrentSpeed_Surface_mps_Mean",
                            "test": "d => d[\"Total_CurrentSpeed_Surface_mps_Mean\"][\"Value\"] > 0 && d[\"Total_CurrentSpeed_Surface_mps_Mean\"][\"Value\"] < 0.8"
                        }
                    ]
                },
                {
                    "conditionId": "Y",
                    "label": "Surface current 0.8 to 2 knots",
                    "longLabel": "Surface current 0.8 to 2 knots",
                    "test": "d => d[\"Total_CurrentSpeed_Surface_mps_Mean\"][\"Value\"] >= 0.8 && d[\"Total_CurrentSpeed_Surface_mps_Mean\"][\"Value\"] < 2",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "Total_CurrentSpeed_Surface_mps_Mean",
                            "test": "d => d[\"Total_CurrentSpeed_Surface_mps_Mean\"][\"Value\"] >= 0.8 && d[\"Total_CurrentSpeed_Surface_mps_Mean\"][\"Value\"] < 2"
                        }
                    ]
                },
                {
                    "conditionId": "Z",
                    "label": "Surface current > 2 knots",
                    "longLabel": "Surface current > 2 knots",
                    "test": "d => d[\"Total_CurrentSpeed_Surface_mps_Mean\"][\"Value\"] >= 2",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "Total_CurrentSpeed_Surface_mps_Mean",
                            "test": "d => d[\"Total_CurrentSpeed_Surface_mps_Mean\"][\"Value\"] >= 2"
                        }
                    ]
                },
                {
                    "conditionId": "AA",
                    "label": "Subsea/seabed current significant according to ROV supervisor",
                    "longLabel": "Subsea/seabed current significant according to ROV supervisor",
                    "test": "d => false",
                    "forecastConditions": [
                        {
                            "parameterGroupId": null,
                            "test": "d => false"
                        }
                    ]
                }
            ]
        },
        {
            "label": "VISIBILITY",
            "conditions": [
                {
                    "conditionId": "AD",
                    "label": "Horizontal visibility 1000m to 1nm",
                    "longLabel": "Horizontal visibility 1000m to 1nm",
                    "test": "d => d[\"Visibility_m\"][\"Value\"] >= 1.000 && d[\"Visibility_m\"][\"Value\"] < 1.852",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "Visibility_m",
                            "test": "d => d[\"Visibility_m\"][\"Value\"] >= 1.000 && d[\"Visibility_m\"][\"Value\"] < 1.852"
                        }
                    ]
                },
                {
                    "conditionId": "AE",
                    "label": "Horizontal visibility 600 to 1000m",
                    "longLabel": "Horizontal visibility 600 to 1000m",
                    "test": "d => d[\"Visibility_m\"][\"Value\"] >= 0.600 && d[\"Visibility_m\"][\"Value\"] < 1.000",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "Visibility_m",
                            "test": "d => d[\"Visibility_m\"][\"Value\"] >= 0.600 && d[\"Visibility_m\"][\"Value\"] < 1.000"
                        }
                    ]
                },
                {
                    "conditionId": "AF",
                    "label": "Horizontal vsibility < 600m",
                    "longLabel": "Horizontal vsibility < 600m",
                    "test": "d => d[\"Visibility_m\"][\"Value\"] < 0.600",
                    "forecastConditions": [
                        {
                            "parameterGroupId": "Visibility_m",
                            "test": "d => d[\"Visibility_m\"][\"Value\"] < 0.600"
                        }
                    ]
                },
                {
                    "conditionId": "AG",
                    "label": "Poor visibility subsea according to ROV supervisor",
                    "longLabel": "Poor visibility subsea according to ROV supervisor",
                    "test": "d => false",
                    "forecastConditions": null
                }
            ]
        },
        {
            "label": "VESSEL MOTION",
            "conditions": [
                {
                    "conditionId": "AI",
                    "label": "LNG/ LPG carrier roll \u2264 2\u00b0",
                    "longLabel": "LNG/ LPG carrier roll \u2264 2\u00b0",
                    "test": "d => false",
                    "forecastConditions": null
                },
                {
                    "conditionId": "AJ",
                    "label": "LNG/LPG carrier roll > 2\u00b0",
                    "longLabel": "LNG/LPG carrier roll > 2\u00b0",
                    "test": "d => false",
                    "forecastConditions": null
                },
                {
                    "conditionId": "AK",
                    "label": "Condensate tanker exceeds criteria",
                    "longLabel": "Condensate tanker exceeds criteria (taut hawser with one pull @ 27% or two pulls >19% new dry breaking strength in any 30 minute period, FLNG and carrier distance \u226450m, angle >35\u00b0, fishtail >45\u00b0)",
                    "test": "d => false",
                    "forecastConditions": null
                },
                {
                    "conditionId": "AL",
                    "label": "Prelude trim > 0.5\u00b0 or list > 0.5\u00b0",
                    "longLabel": "Prelude trim > 0.5\u00b0 or list > 0.5\u00b0",
                    "test": "d => false",
                    "forecastConditions": null
                },
                {
                    "conditionId": "AM",
                    "label": "Prelude loading arm relative motion",
                    "longLabel": "Prelude loading arm relative motion: surge < 2.5m, sway < 1.7m, heave < 1.4m. Assume 70% of design limit",
                    "test": "d => false",
                    "forecastConditions": null
                },
                {
                    "conditionId": "AO",
                    "label": "Prelude rate of turn > 2\u00b0 per minute",
                    "longLabel": "Prelude rate of turn > 2\u00b0 per minute",
                    "test": "d => false",
                    "forecastConditions": null
                }
            ]
        }
    ],
    "restrictionDescriptions": [
        {
            "id": "R1",
            "description": "These two activities can be carried out together on the facility, but not within each others sphere of influence."
        },
        {
            "id": "R2",
            "description": "No High or Medium risk maintenance activities during an unscheduled facility trip in order to minimise risks on the facilities until the cause of the trip is understood. High or Medium risk tasks may be undertaken if associated with facility recovery."
        },
        {
            "id": "R3",
            "description": "Hatches to be closed when breaking into any of the following process areas: LNG/LPG offloading arms, offloading headers and associated piping, endflash column and LNG expander in module 3S and LNG rundown header."
        },
        {
            "id": "R4",
            "description": "By exception only - refer to Offshore Personnel Transfer Procedure (OPS_PRE_013445)."
        },
        {
            "id": "R5",
            "description": "Lifts over live equipment or piping containing process gas/liquids must meet the Non-routine Complex Lift requirements in the Prelude Lifting and Hoisting Operations Supplement (OPS_PRE_010176)."
        },
        {
            "id": "R6",
            "description": "No helicopter landing during excessive flaring during full process blowdown."
        },
        {
            "id": "R7",
            "description": "Risk of vessel under helideck being impacted by dropped object from helideck (including falling helicopter). Risk assessment required. Requires approval from OIM."
        },
        {
            "id": "R8",
            "description": "Helicopter movement is not permitted as it is assumed all ISVs are involved in berthing operations, thereby having no available standby vessel."
        },
        {
            "id": "R9",
            "description": "No crane operations on the starboard side during LNG/LPG berthing and unberthing. Crane operations on the starboard side only permitted within the Prelude handrails during LNG/LPG offloading."
        },
        {
            "id": "R10",
            "description": "No personnel transfer at the aft, where ISV is under the helideck"
        },
        {
            "id": "R11",
            "description": "Either deck can be used subject to relevant wind as per relevant wind polar chart. The offtake tanker and vessels must be clear of the 210\u00b0 obstacle free sector as defined by the chevron markings on the helideck.\n"
        },
        {
            "id": "R12",
            "description": "LNG/LPG carrier berthing parameters are defined in the relevant Terminal Information Book (OPS_GEN_004647 and OPS_GEN_012470) and pilotage procedures. The DIODORE daily operability report shall be referenced as guidance for the expected LNG/LPG carrier motions in relation to the basis of design  parameters. This includes support vessel environmental limitations of operation."
        },
        {
            "id": "R13",
            "description": "As determined in the risk assessment specific to the activity. \n\nIn the event the CargoMax loading computer is unavailable: \n\u2022 No berthing, unberthing and offloading activities.\n\u2022 Production is restricted \u2013 see Intact Stability Procedure (OPS_PRE_007752)."
        },
        {
            "id": "R14",
            "description": "Only for activities without any personnel on the topsides."
        },
        {
            "id": "R15",
            "description": "Ativities on wells/flowlines outside the Prelude 1500m exclusion zone is dependent on risk assessment. Weather limits are defined by each individual vessel."
        },
        {
            "id": "R16",
            "description": "\u2022 >2\u00b0 \u2013 no helicopter movement (landing/take off) at night.\n\u2022 >3\u00b0 \u2013 No helicopter movement (landing/take off) at day or night."
        },
        {
            "id": "R17",
            "description": "Decision made by the helicopter pilot, and may be considered for medevac / surface-air-rescue purposes. Refer to aviation procedures."
        },
        {
            "id": "R18",
            "description": "\u2022 For mean wind speed \u2265 22 knots, no Favco crane operations (A,B,C,D,F) for all lift modes(maximum operating wind speed is 22.5 knots).\n\u2022 For mean wind speed \u2265 34 knots, no Palfinger crane (G) or NOV crane (E) operations for all lift modes."
        },
        {
            "id": "R19",
            "description": "Allowed after shutdown/isolation of particular unit/system with impaired SCE."
        },
        {
            "id": "R20",
            "description": "Restricted as Prelude may require thrusters for heading control as determined by 'AdviceToMaster' application."
        },
        {
            "id": "R21",
            "description": "Not permitted if heading control is required."
        },
        {
            "id": "R22",
            "description": "Allowed to effect mitigating measures on the impaired SCE."
        },
        {
            "id": "R23",
            "description": "Allowed after emergency is in under control, and approved by OIM."
        },
        {
            "id": "R24",
            "description": "No berthing operations during the process of shutting down, trip and/or emergency depressurisation. If a trip occurs whilst in the process of berthing, the vessel is to be advised to hold or alter its course until the cause of the trip is known. Intent is to plan berthing prior to or after the scheduled shutdown and EDP activities to enable personnel involved to focus on one high risk activity at a time. For unscheduled trips, this is to minimise risks  facilities until cause of the trip is identified / understood. "
        },
        {
            "id": "R25",
            "description": "Condensate offloading only - Stop cargo flow and ensure vent riser is shut on amber alert"
        },
        {
            "id": "R26",
            "description": "OIM approval required for hot work, and decision to keep the vessel within the FLNG 1500m exclusion zone."
        },
        {
            "id": "R27",
            "description": "Risk assessment required where rate of turn > 2o/minute but < 3o/minute. Not permitted where rate of turn > 3o/minute. Use thrusters to mitigate."
        },
        {
            "id": "R28",
            "description": "\u2022 For mean wind speed  \u2265 25 knots, TTL to consider stopping condensate loading and stopping LNG/LPG transfer and disconnect from Prelude after consultation with LTT, vessel\u2019s Master and OIM. Restricted manual handling on the deck of supply boats and tugs.\n\u2022 For mean wind speed \u2265 27 knots. Cease offloading LNG/LPG/condensate, gas free, disconnect and retract marine loading arm. TTL to consider unberthing and unmooring after consultation with LTT, vessel\u2019s Master and OIM.\n\u2022  For mean wind speed \u2265 30 knots and/or wave height and swell (HS) > 3.0m. Unberth side-by-side/unmoor tanker and sail clear of Prelude. No manual handling on decks of supply boats and tugs, e.g. no launching of fast rescue craft."
        },
        {
            "id": "R29",
            "description": "This restriction only applies if outside of 20\u00b0 either side of Prelude\u2019s heading, this is not to exceed 30 knots.\nMean wind speed \u2265 15 knots. TTL to consider stopping condensate loading and disconnect hose after consultation with LTT, vessel\u2019s Master and OIM.  Cease tandem mooring.\nMean wind speed \u2265 20 knots. Cease offloading condensate. Consider unmooring."
        },
        {
            "id": "R30",
            "description": "Driven by standby vessel limitations (manual handling on deck limit is 30 knots)."
        },
        {
            "id": "R31",
            "description": "Not permitted when ESDV criteria is not met specific to the LNG offloading arm zone."
        },
        {
            "id": "R32",
            "description": "Diving HAZID and isolation plan must be completed and all conditions are met before the activity progresses. Diving operations dictate the vessels positions around the facility."
        },
        {
            "id": "R33",
            "description": "Not permitted if there will be flow into the FLNG blowdown zone with impaired SCE."
        },
        {
            "id": "R34",
            "description": "Not permitted for the riser with impaired riser emergency shutdown valve."
        },
        {
            "id": "R35",
            "description": "Not permitted for the riser with impaired fail-closed riser base valve."
        },
        {
            "id": "R36-61",
            "description": "Deleted."
        },
        {
            "id": "R62",
            "description": "Pending completion of an operational risk assessment. If a diver is in the decompression chamber, limit activities that can potentially lead to facility evacuation."
        },
        {
            "id": "R63-69",
            "description": "Deleted."
        },
        {
            "id": "R70",
            "description": "\u2022 Crane G is to be stopped and racked.\n\u2022 For a port landing/take-off: Crane F stopped and inboard (does not need to be racked);  Cranes C and E stopped (does not need to be inboard); Cranes A, B and D can continue working. \n\u2022 For starboard landing/take off: Crane E stopped and inboard (does not need to be racked), crane F stopped (does not need to be inboard), cranes A, B, C and D can continue working."
        },
        {
            "id": "R71",
            "description": "Subject to risk assessment considering type and location of activity, escape time/route for the vessel and weather conditions. "
        },
        {
            "id": "R72",
            "description": "\u2022 For > 2m significant wave height, condensate tanker to cease loading, disconnect, unmoor tanker and sail. Side-by-side tanker to consult between LNGC/LPG carrier Master and TTL to consider stopping cargo and disconnect from Prelude \n\u2022 For > 3m significant wave height, side by side tanker to unberth and sail clear of Prelude."
        },
        {
            "id": "R73",
            "description": "Flare boom access above the maintenance deck level requires the facility to be shutdown with no flare to manage heat radiation. Vents (AGRU and marine vent) to be assessed regardless of facility status. All vents must be rerouted from flare stack."
        },
        {
            "id": "R74",
            "description": "Deleted."
        },
        {
            "id": "R75",
            "description": "Prior approval of aviation Technical Authority (TA1) is required for any activity involving Remotely Piloted Airborne Systems (RPAS), drones or Unmanned Airborne vehicles (UAV)."
        },
        {
            "id": "R76-77",
            "description": "Deleted"
        },
        {
            "id": "R78",
            "description": "SIMOPS to recognise risk of hydrocarbon release through carrier vents can impact starboard side of vessel, including behind the blast wall."
        },
        {
            "id": "R79",
            "description": "Work over the side is acceptable provided a standby vessel is available and not assigned to the carrier (i.e. third vessel is available). Only portside work over the side is permitted during side-by-side berthing, product transfer, and unberthing."
        },
        {
            "id": "R80",
            "description": "Deleted."
        },
        {
            "id": "R81",
            "description": "SIMOPS to recognise vessel/carrier exhaust fumes may cause a hazard during ventilation of confined spaces."
        },
        {
            "id": "R82",
            "description": "SIMOPS to recognise hydrocarbons are flowing behind the blast wall through condensate export system."
        },
        {
            "id": "R83",
            "description": "Only permitted once LNGC/LPG carrier mooring is complete and pilot has verified control of all ignition sources on carrier as per Terminal Information Book requirements (OPS_GEN_004647 and OPS_GEN_012470)."
        },
        {
            "id": "R84",
            "description": "Deleted"
        },
        {
            "id": "R85",
            "description": "Activity permitted, but does not include use of internal combustion engines in Zone 1 areas. "
        },
        {
            "id": "R86",
            "description": "Activity permitted if risk assessment specific to the activity confirms no large gas cloud will be generated. "
        },
        {
            "id": "R87",
            "description": "Activity is permitted if the conditions of the document Plant Configuration Assessment with SW2 WIR Hatch Open (2000-010-OA-3323-00004) are met."
        },
        {
            "id": "R88",
            "description": "Activity not permitted for any liquid LNG/LPG systems. Activity permitted pending results of SIMOPS risk assessment otherwise."
        },
        {
            "id": "R89",
            "description": "Activity not permitted if leak testing with Hydrocarbons. Activity permitted if non-Hydrocarbon leak testing."
        },
        {
            "id": "R90",
            "description": "Lifting over live Hydrocarbon lines is not permitted. Refer to Prelude Lifting and Hoisting Operations Procedure (OPS_GEN_015990) for lifting over live equipment. All other non-routine lifts permitted according to Lifting & Hoisting Standard OPS_PRE_010176."
        },
        {
            "id": "R91",
            "description": "Activity permitted upon the completion of an operational risk assessment."
        },
        {
            "id": "R92",
            "description": "Gangway must be closed (no traffic) during these activities"
        },
        {
            "id": "R93",
            "description": "Supporting vessel movements to be coordinated on a daily basis through SIMOPS."
        },
        {
            "id": "R94",
            "description": "No personnel transfers to from AFT bow catcher when the ASV is connected.\nNo personnel transfers to watertight doors port when ASV connected port, No personnel transfers to watertight doors port when ASV connected AFT/STBD."
        },
        {
            "id": "R95",
            "description": "Allowed as per conditions of ASOG, Governed by ASV crane operating limits"
        },
        {
            "id": "R96",
            "description": "All connections to be made prior to sunset, product transfer and disconnect allowed during nightshift"
        },
        {
            "id": "R97",
            "description": "Refer ASV bunkering procedure for tidal current limits "
        },
        {
            "id": "R98",
            "description": "All crane operations permitted, however the gangway is to be considered a live line, any lifts over the gangway will be considered a non-routine lift and gangway must be closed. Refer to Prelude Lifting and Hoisting Operations Procedure (OPS_GEN_015990) for lifting over live equipment"
        },
        {
            "id": "R99",
            "description": "Refer supply boat loading/unloading crane operability and ASV connection table"
        },
        {
            "id": "R100",
            "description": "With ASV connection PORT: Crane F stopped (does not need to be racked or inboard);  Crane G stopped and inboard (does not need to be racked); ASV Cranes operating as per ASV MOPO, All other cranes OK to operate.\nWith ASV connection AFT: Cranes G stopped and inboard (does not need to be racked); Crane F stopped and inboard (does not need to be racked unless at night); ASV Cranes operating as per ASV MOPO, All other cranes OK to operate."
        },
        {
            "id": "R101",
            "description": "Refer ASV Airgap limits and Air Gap Analysis"
        },
        {
            "id": "R102",
            "description": "Governed by ASV gangway operating limits"
        },
        {
            "id": "R103",
            "description": "Governed by ASOG operating limits for thruster/Main Engine load limits of the ASV.  The ASV Bridge Team to monitor Prelude's heading rate of change continuously and operate within the ASOG parameters.  As a guideline, the ASV will be able to follow Prelude safely upto a Prelude Heading rate of change of 4 deg per minute as per  Dynamic Capability Analysis and Prelude FLNG Gangway operation with follow target."
        },
        {
            "id": "R104",
            "description": "Ensure that the ASV OIM is notified of any heading changes on Prelude from Prelude's thruster operations"
        },
        {
            "id": "R105",
            "description": "Work over the side is acceptable provided a standby vessel is available and not assigned to the carrier (i.e. third vessel is available). Only portside work over the side is permitted during side-by-side berthing, product transfer, and unberthing."
        },
        {
            "id": "R106",
            "description": "Refer field management plan for the prohibited lift sections for ASV overside crane operations based on different Prelude headings."
        }
    ],
    "activityDescriptions": [
        {
            "id": "A1",
            "label": "Shutdown (boilers offline or running on diesel)",
            "description": "Topsides facilities have stopped production and are hydrocarbon-free and inerted. In this state, the boilers are either offline (facility is powered by the essential diesel generators) OR boilers are runnIng on diesel."
        },
        {
            "id": "A2",
            "label": "Shutdown (boilers running on fuel gas)",
            "description": "Topsides facilities have stopped production and are hydrocarbon-free and inerted, so do not contain any fuel gas. Boilers are operating on fuel gas from the LNG vaporiser."
        },
        {
            "id": "A3",
            "label": "Shutdown and depressurised (e.g. post-emergency depressurisation)",
            "description": "Facility is shutdown and hydrocarbon is depressurized to approximately flare pressure with the boilers operating on diesel or fuel gas from the LNG vaporizer."
        },
        {
            "id": "A4",
            "label": "Upstream in operation. Warm and cold end shutdown and depressurised",
            "description": "Well(s) open with U08000 to U10000 in operation (condensate running down to storage). Warm end and cold end are not in operation and hydrocarbon is depressurised to approximately flare pressure. Boilers running on diesel, fuel gas from LNG vaporiser or wet fuel gas."
        },
        {
            "id": "A5",
            "label": "Upstream and warm end in operation",
            "description": "Warm end of the facility in operation. This covers units up to and including U13500. Cold end of facility is not in operation and hydrocarbon is depressurised to approximately flare pressure."
        },
        {
            "id": "A6",
            "label": "Facility pressurized or in full operation",
            "description": "Facility is producing LNG, LPG and condensate in routine steady state operation OR facility is pressurized (and shutdown /partialy shutdown). Where facility operating mode is not covered by other modes (A1-A5), it should fall under this mode."
        },
        {
            "id": "A8",
            "label": "EDP/ESD Testing",
            "description": ""
        },
        {
            "id": "B1",
            "label": "High risk hot work on Prelude ",
            "description": "Includes all high risk hotwork on the facility as described in the Permit to Work Manual. Involves open flame in the production unit (e.g. welding on the structure)"
        },
        {
            "id": "B2",
            "label": "High risk hot work inside FLNG 1500m safety zone (exposed flame)",
            "description": "High Risk Hot Work on the deck of the vessel that potentially produces an exposed/naked flame (e.g. from welding, brazing, flame cutting, direct-fired equipment and burning debris)."
        },
        {
            "id": "B3",
            "label": "Medium risk hot work ",
            "description": "Includes all Medium Risk Hotwork on the facility as described in the Permit to Work Manual (e.g. carrying non-intrinsically safe laptop through facility to perform equipment analysis)."
        },
        {
            "id": "B4",
            "label": "Breaking into hydrocarbon systems",
            "description": "Intrusive work into any flange, screwed fitting or seal etc which is being used to contain hydrocarbons on Prelude excluding the condensate reel and hoses located aft of the blast wall and the removal of small isolatable instruments."
        },
        {
            "id": "B5",
            "label": "Breaking into hydrocarbon systems (condensate reel/hose after the blast wall)",
            "description": "Intrusive work into any flange, screwed fitting or seal etc which is being used to contain hydrocarbons in the condensate reel and hoses located aft of the blast wall. Exceptions to this are the removal of small isolatable instruments."
        },
        {
            "id": "B6",
            "label": "Gas freeing and inert gas operations",
            "description": "This category includes washing process vessels, tanks and equipment using hot water hose or steam lance and the use of inert gas systems. For the purposes of vessel management and the maintenance of specific vessels, inert gas operations can be considered on an individual basis ie individual vessels can be considered to be isolatable. These activities are specific for preparation for maintenance and do not refer to tank inerting during normal production operations."
        },
        {
            "id": "B7",
            "label": "Flare boom access",
            "description": "Access to the flare tower restricted heat radiation zone. This excludes the flare boom module and flare tip."
        },
        {
            "id": "B8",
            "label": "Confined space entry",
            "description": "Refer to the Confined Space Entry Manual for the definition of confined spaces."
        },
        {
            "id": "B9",
            "label": "Leak testing",
            "description": "Leak testing involves testing a system for leaks. This includes high pressure leak tests (e.g HPLT nitrogen helium mixture or high pressure nitrogen) but excludes pressure tests and hydrocarbon service testing (refer to start Up from Depressurised State). "
        },
        {
            "id": "B10",
            "label": "Work over the side",
            "description": "Any work or inspection undertaken over water and outside the external safety handrails, including rope access."
        },
        {
            "id": "B11",
            "label": "Remotely piloted airborne systems (RPAS)",
            "description": "The use of remote pilioted aerial systems, drones or unmanned aerial vehicles on the facility. "
        },
        {
            "id": "B12",
            "label": "Removal of water intake riser/large machinery space hatches",
            "description": "The removal of either the water intake riser (SW2) or the aft machinery space hatch (above the STGs) to access the machinery space."
        },
        {
            "id": "B13",
            "label": "Removal of large machinery space hatches.",
            "description": "The removal of hydraulic (aft, portside of living quarters) hatches."
        },
        {
            "id": "B14",
            "label": "Removal of the forward starboard hatch",
            "description": "The removal of the forward starboard hatch (forecastle deck). "
        },
        {
            "id": "B15",
            "label": "Removal of the thruster hatch",
            "description": "The removal of the thruster hatch (aft of the Living Quarters)."
        },
        {
            "id": "C1",
            "label": "Non-routine lifts",
            "description": "A crane lift on the facility using the onboard cranes in the process areas (e.g. tandem, engineered lifts), and as defined in the Lifting & Hoisting Procedure. This excludes crane movements for supply vessel transfers."
        },
        {
            "id": "C2",
            "label": "Thruster operations, including thruster testing",
            "description": "Operation of the thruster(s) to change to required heading."
        },
        {
            "id": "C3",
            "label": "Routine crane operations starboard side ",
            "description": "Cranage for the starboard side of the facility, including cranage for maintenance considering inboard lifts only (within handrails of Prelude).  This excludes routine lifts over live process lines. Note: LNG/LPG cargo is loaded at starboard side"
        },
        {
            "id": "C4",
            "label": "Routine crane operations port side ",
            "description": "Cranage for the port side of the facility, including cranage for maintenance, unloading and loading of supply boats. This excludes routine lifts over live process lines."
        },
        {
            "id": "C5",
            "label": "Helicopter movement, take off and landing from Prelude",
            "description": "All helicopter interaction as determined by Helicopter Landing Officer (HLO) and at 15 minutes post take-off."
        },
        {
            "id": "C6",
            "label": "Helicopter movement, take off and landing from ASV",
            "description": "All helicopter interaction as determined by Helicopter Landing Officer (HLO) and at 15 minutes post take-off."
        },
        {
            "id": "C7",
            "label": "Supply boat loading/unloading starboard",
            "description": "All ship movement to the facility for loading/unloading including bunkering within the FLNG 1500m safety zone at the starboard side."
        },
        {
            "id": "C8",
            "label": "Supply boat loading/unloading including bunkering port",
            "description": "All ship movement to the facility for loading/unloading including bunkering within the FLNG 1500m safety zone at the port side."
        },
        {
            "id": "C9",
            "label": "Entry of ancillary vessel within the 1500m FLNG safety zone",
            "description": "Any ancillary vessel's entry or presence within the FLNG 1500m safety zone. Ancillary vessels do not include the dedicated ISVs (tugs), PSVs (supply vessels) and MPSVs (for underwater works)."
        },
        {
            "id": "C10",
            "label": "Personnel transfer to/from facility by side watertight doors or aft bow catcher",
            "description": "This includes the transfer of personnel to/from the facility to a vessel via permitted access at the stern or two side doors."
        },
        {
            "id": "C11",
            "label": "Personnel transfer via the FROG",
            "description": "The transfer of personnel to/from the facility to a vessel via the FROG (personnel transfer capsule manufactured by Reflex Marine)."
        },
        {
            "id": "C12",
            "label": "LNG/LPG tanker berthing (side-by-side)",
            "description": "Ship movement within the FLNG 1500m safety zone and the running and effecting of mooring lines until all fast is declared."
        },
        {
            "id": "C13",
            "label": "LNG/LPG tanker offloading (side-by-side)",
            "description": "From when all fast is declared until all mooring lines gone, including connecting of LNG/LPG loading arms, transfer of LNG/LPG to the carrier, and any waiting period whilst moored alongside."
        },
        {
            "id": "C14",
            "label": "LNG/LPG tanker unberthing (side-by-side)",
            "description": "This includes ship movement from all mooring lines gone until the carrier exits the FLNG 1500m safety zone."
        },
        {
            "id": "C15",
            "label": "Condensate tanker mooring (tandem)",
            "description": "This includes ship movement within the FLNG 1500m safety zone and the running and effecting of mooring hawser until all fast is declared."
        },
        {
            "id": "C16",
            "label": "Condensate tanker offloading (tandem)",
            "description": "From when all fast is declared until the mooring hawser is all gone, including connecting of condensate transfer hose to the tanker, transfer of condensate to the carrier, and any waiting period whilst moored in tandem. "
        },
        {
            "id": "C17",
            "label": "Condensate tanker unberthing (tandem)",
            "description": "This includes ship movement from when mooring hawser is all gone and until the carrier exits the FLNG 1500m safety zone."
        },
        {
            "id": "D1",
            "label": "Wells and flowline commissioning",
            "description": "Commissioning of wells, flowlinea, subsea controls and hardware to the facility."
        },
        {
            "id": "D2",
            "label": "Breaking into hydrocarbon system subsea",
            "description": "This includes all work subsea breaking containment intrusive work into the process."
        },
        {
            "id": "D3",
            "label": "Well intervention activities ",
            "description": "This includes all work associate with the Prelude wells. "
        },
        {
            "id": "D4",
            "label": "ROV activities within the FLNG safety zone",
            "description": "This activity is within the FLNG 1500m safety zone which includes inspection,  intervention work and the use of multi-purpose supply vessels."
        },
        {
            "id": "D5",
            "label": "ROV activities outside the FLNG safety zone",
            "description": "This activity is outside  the FLNG 1500m safety zone (e.g. flowlines and around the wells safety zone) which includes inspection, intervention work and the use of multi-purpose supply vessels."
        },
        {
            "id": "E1",
            "label": "ASV Connected PORT (Rotated 45 deg AFT or TRANSVERSE)",
            "description": ""
        },
        {
            "id": "E2",
            "label": "ASV Connected AFT (Rotated 30 or 40 deg STBD as required)",
            "description": ""
        },
        {
            "id": "E3",
            "label": "ASV receiving cargo/bunkering operations whilst connected",
            "description": ""
        }
    ]
}