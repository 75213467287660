import "../../style.css";

import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuAppBar from "../components/MenuAppBar";
import { Layout } from "../layout";

export const ModulesOverview = () => {
    const { _, lat, lon } = useParams();

    const pathMRE = lat && lon ? `/mre/${lat}/${lon}` : "/mre";
    const pathTPXO = lat && lon ? `/tpxo/${lat}/${lon}` : "/tpxo";
    const pathMm2Dlp = lat && lon ? `/mm2dlp/${lat}/${lon}` : "/mm2dlp";

    const navigate = useNavigate();

    // logos/images
    const logo_mre = new URL("../../logos/wave_harvest.png", import.meta.url);
    const logo_tpxo = new URL("../../logos/tidal_forces.jpg", import.meta.url);
    const logo_mcpml = new URL("../../logos/current.jpg", import.meta.url);
    const logo_map = new URL("../../logos/map_of_earth.png", import.meta.url);
    const logo_mm2dlp = new URL("../../logos/logo_meteomatics.png", import.meta.url);
    const logo_mopo = new URL("../../logos/mopo_matrix.png", import.meta.url);
    const logo_weatherwindow = new URL("../../logos/mopo_matrix.png", import.meta.url);
    const logo_pdf_forecast = new URL("../../logos/pdf.png", import.meta.url);

    const content = (
        <>
            <MenuAppBar moduleName="" />
            <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid xs={4}>
                    <Card onClick={() => navigate("/map")}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={String(logo_map)}
                                alt="Module"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Map
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    textAlign={"justify"}
                                    height={80}
                                >
                                    Allows you to select coordinates from a map and automatically
                                    apply these in other modules.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card onClick={() => navigate(pathMm2Dlp)}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={String(logo_mm2dlp)}
                                alt="Module"
                                sx={{ objectFit: "contain" }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Meteomatics2dlp
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    textAlign={"justify"}
                                    height={80}
                                >
                                    Retrieve Meteomatics data in DLP format.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card onClick={() => navigate(pathMRE)}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={String(logo_mre)}
                                alt="MRE"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    MRE
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    textAlign={"justify"}
                                    height={80}
                                >
                                    Generates a report on the yield of selected wave energy
                                    harvesting devices for a given location.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card onClick={() => navigate(pathTPXO)}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={String(logo_tpxo)}
                                alt="TPXO"
                                // sx={{ objectFit: "contain" }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    TPXO
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    textAlign={"justify"}
                                    height={80}
                                >
                                    Tide prediction.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card onClick={() => navigate("/mcp-ml")}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={String(logo_mcpml)}
                                alt="MCP-ML"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    MCP-ML4
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    textAlign={"justify"}
                                    height={80}
                                >
                                    Current prediction based on data from MetNet Global.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card onClick={() => navigate("/mopo")}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={String(logo_mopo)}
                                alt="Module"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    MOPO
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    textAlign={"justify"}
                                    height={80}
                                >
                                    The Manual of Permitted Operations (MOPO) identifies various offshore activities and situations where there is an increase in hazards or reduction in control mitigations.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card onClick={() => navigate("/weather-window")}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={String(logo_weatherwindow)}
                                alt="Module"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    WeatherWindow
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    textAlign={"justify"}
                                    height={80}
                                >
                                    Define your own operation permissions.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card onClick={() => navigate("/pdf-forecasts")}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={String(logo_pdf_forecast)}
                                alt="Module"
                                sx={{ objectFit: "contain" }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    PDF Forecasts
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    textAlign={"justify"}
                                    height={80}
                                >
                                    Receive PDF forecasts.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </>
    );

    return <Layout content={content} moduleName="Meteomatics2dlp" />;
};
