import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
// import {
//     experimental_extendTheme as extendTheme,
//     Experimental_CssVarsProvider as CssVarsProvider,
//   } from '@mui/material/styles';

const shell_yellow = "#fbd103";
const shell_grey = "#404040"; //'#323232'
const shell_red = "#ce2925";

// enable the "GO" button variant
declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        GO: true;
        confirm: true;
        cancel: true;
    }
}

const theme = createTheme({
    cssVarPrefix: "--css",
    palette: {
        // shell: {
        //     yellow: '#fbd103'
        // },
        primary: {
            main: shell_yellow,
            contrastText: shell_grey,
        },
        secondary: {
            main: "#ce2925",
            contrastText: "#fbd103",
        },
        info: {
            main: shell_grey,
            contrastText: shell_grey
        }
    },
    typography: {
        fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
        h6: {
            color: shell_grey,
            textAlign: "center",
            // fontWeight: 700,
            flexGrow: 1,
            verticalAlign: "center",
            // fontFamily: '',
            // letterSpacing: 0,
            // fontSize: 45
        },
        // custom variant won't align to center... for some reason.
        moduleHeader: {
            color: shell_grey,
            textAlign: "center",
            fontWeight: 700,
            fontSize: 40,
        },
        footer: {
            // color: shell_grey,
            color: red,
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "GO" },
                    style: {
                        fontSize: 14,
                        fontWeight: 700,
                        backgroundColor: shell_yellow,
                        color: shell_grey,
                    },
                },
                {
                    props: { variant: "confirm" },
                    style: {
                        fontSize: 14,
                        fontWeight: 500,
                        backgroundColor: shell_yellow,
                        color: shell_grey,
                    },
                },
                {
                    props: { variant: "cancel" },
                    style: {
                        fontSize: 14,
                        fontWeight: 500,
                        backgroundColor: shell_red,
                        color: shell_yellow,
                    },
                },
            ],
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: shell_grey,
                },
            },
        },
    },
});

export default theme;
