import dayjs from "dayjs";
import { ILiveData, ILiveDataSetStation } from "../types/ILiveData";
import { IActivity, IConditionCategory, IForecastData, IForecastPermissions, IMopoSpecifications, IPermission } from "../types/IMopo";
import { IMapForLiveDataAndForecastData } from "./parametermapping";
import { IConditionCategorisation, IWeatherWindowConfig } from "../types/IWeatherWindow";
import * as SunCalc from 'suncalc3';
import moment from "moment";



export const getForecastPermissions = (
    selectedActivity: IActivity, 
    mopoSpecifications: IMopoSpecifications,
    forecastData: IForecastData,
    parameterMap: IMapForLiveDataAndForecastData[]
) => {

    console.log('[getForecastPermissions] forecastData: ', forecastData);

    // TODO: Combine with getForecastPermissions() from weatherwindows.ts

    // Returns the permissions for the selectedActivity for each point in the forecastData.

    //// get applied conditions

    let allForecastPermissions: IForecastPermissions = {};

    // go through each parameter of the forecastData
    for (const [nameForecastData, data] of Object.entries(forecastData)) {

        let forecastPermissions: (null | IPermission)[] = Array(data.Rows.length).fill(null);

        // go through each conditionCategory
        for (const conditionCategory of mopoSpecifications.conditionCategories) {

            // go through each condition
            for (const condition of conditionCategory.conditions) {

                // check if forecastConditions exists (this is not certain due to incomplete mopo specs)
                if (condition.forecastConditions) {
                    // go through each forecastCondition
                    for (const forecastCondition of condition.forecastConditions) {
                        // if the forecastCondition has parameterGroupId == parameter, test is this condition is applied
                        if (forecastCondition.parameterGroupId == nameForecastData) {

                            const testFunction = eval(forecastCondition.test)

                            // go through all data points
                            data.Rows.forEach((row, idx) => {
                                
                                // get formattedValue
                                const formattedValue = formatValue(row.Value, parameterMap.filter(parameter => parameter.nameForecastData == nameForecastData)[0])["Value"];

                                // get formattedData to input in testFunction
                                // ugly workaround for passing forecastData to testFunction
                                const formattedData = {[`${nameForecastData}`]: {"Value": formattedValue}};

                                // test
                                if (testFunction(formattedData)) {

                                    // get the appliedPermission from all permission of the selectedActivity
                                    const appliedPermission = selectedActivity.permissions.filter((cA) => cA.conditionId == condition.conditionId)[0];

                                    forecastPermissions[idx] = appliedPermission
                                } 
                            });
                        }    
                    }
                }
            }
        }

        allForecastPermissions[nameForecastData] = forecastPermissions;

    }

    return (allForecastPermissions)
}

export const getLivePermissions = (
    selectedActivity: IActivity, 
    mopoSpecifications: IMopoSpecifications,
    liveData: ILiveData,
    parameterMap: IMapForLiveDataAndForecastData[]
) => {

    //// format all data according to the formatting provided by the parameterMap
    let formattedData = {};

    for (const [nameParameter, data] of Object.entries(liveData)) {

        // get formattedValue
        const formattedValue = formatValue(data.Value, parameterMap.filter(parameter => parameter.nameLiveData == nameParameter)[0])["Value"];

        formattedData = {
            ...formattedData,
            [nameParameter] : {
                "Value": formattedValue
            }
        }

    }
    
    //// get applied conditions

    let allPermissions: IForecastPermissions = {};

    // go through each parameter of the forecastData
    for (const [nameParameter, data] of Object.entries(liveData)) {

        let permissions: (null | IPermission)[] = Array(1).fill(null);

        // go through each conditionCategory
        for (const conditionCategory of mopoSpecifications.conditionCategories) {

            // go through each condition
            for (const condition of conditionCategory.conditions) {

                const parametersFromTest = getParametersFromTest(condition.test);

                if (parametersFromTest.includes(nameParameter)) {

                    const testFunction = eval(condition.test);
                    
                    // test
                    if (testFunction(formattedData)) {
    
                        // get the appliedPermission from all permission of the selectedActivity
                        const appliedPermission = selectedActivity.permissions.filter((cA) => cA.conditionId == condition.conditionId)[0];
    
                        permissions[0] = appliedPermission
                    } 
                }
            }
        }

        allPermissions[nameParameter] = permissions;

    }

    return (allPermissions)
}

export const formatValue = (
    value: string, 
    parameter: IMapForLiveDataAndForecastData
) => {

    // check if value is empty
    const checkedValue = (value == '') ? '-' : value

    // format value
    const formattedValue = (parseFloat(checkedValue) * parameter.unitScaling).toFixed(parameter.decimals);
    const formattedUnit = parameter.unitScaled

    return {
        "Value": formattedValue,
        "Unit": formattedUnit
    }

}

export const formatLiveData = (
    liveData: ILiveDataSetStation, 
    parameters: IMapForLiveDataAndForecastData[], 
) : ILiveData => {

    console.log('[formatLiveData] liveData: ', liveData)

    let formattedData: ILiveData = {};

    for (const parameterGroup of Object.keys(liveData.GroupValues)) {
    
        if (parameters.map(parameter => parameter.nameLiveData).includes(parameterGroup)) {

            let newItem;

            for (const groupData of liveData.GroupValues[parameterGroup]) {

                // preferably, use the primary item. alternatively, use the last item.
                if (groupData.Primary == true || (!newItem && groupData == liveData.GroupValues[parameterGroup].at(-1))) {

                    // liveData is considered deprecated if measured more than 60 min ago.
                    const deprecationThreshold = 60; // [minutes]
                    const timeNow = dayjs.utc().unix() // [seconds since the Unix Epoch]
                    const timeData = groupData.Timestamp / 1000 // [seconds since ?]
                    const timeSinceTimestamp = (timeNow - timeData) / 60 // [minutes]
                    const isDeprecated = timeSinceTimestamp > deprecationThreshold;

                    newItem = {
                        ...groupData, 
                        isDeprecated: isDeprecated
                    }

                }
            }

            formattedData = {
                ...formattedData, 
                [parameterGroup]: newItem
            }
        }
    }
    
    console.log('[formatLiveData] formattedData: ', formattedData)

    return formattedData
}

export const getLiveDataRange = (liveData: ILiveData, conditionCategorisation: IConditionCategorisation) => {

    const parametersOi = Object.keys(conditionCategorisation).reduce((accumulator, currentLabel) => [...accumulator, ...conditionCategorisation[currentLabel].map(parameter => parameter.nameLiveData)], []);

    //// get time range of liveData

    let liveDataTimeMin = Infinity;
    let liveDataTimeMax = 0;

    for (const [parameter, data] of Object.entries(liveData)) {

        // only consider if parameter is included in parametersOi, and if data is not deprecated
        if (parametersOi.includes(parameter) && !data["isDeprecated"]) {
            if (data["Timestamp"] < liveDataTimeMin) {
                liveDataTimeMin = data["Timestamp"]; 
            }
    
            if (data["Timestamp"] > liveDataTimeMax) {
                liveDataTimeMax = data["Timestamp"]; 
            }
        }
        
    }

    if ((liveDataTimeMin != Infinity) && (liveDataTimeMax != 0)) {
        return ({
            "Min": liveDataTimeMin,
            "Max": liveDataTimeMax
        })
    } else {
        return null
    }
    
}



export const getParametersFromTest = (test: string): string[] => {
    
    // any combination of letters, numbers and _, following "d\[\""
    const regex = /(?<=d\[\")[a-zA-Z0-9_]*/g;

    try {
        const matches = test.match(regex);

        // only return one of each
        return Array.from(new Set(matches))
    } catch {
        return null
    }

}



export const getDaylightParameter = (time: number, latitude: number, longitude: number): string => {

    // time should be in units of [ms]

    // get sun specs at given time and location
    const sunTimes = SunCalc.getSunTimes(moment(time), latitude, longitude);

    // check if given time is between sunrise and sunset
    const isDaylight = (time > (sunTimes.sunriseStart.ts)) && (time < (sunTimes.sunsetEnd.ts));

    // if given time is between sunrise and sunset, return '1', otherwise, return '0'
    return isDaylight ? '1' : '0'
}